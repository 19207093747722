import { useTranslation } from "react-i18next";
import { ReactComponent as Attention } from "../../assets/icons/icon-attention.svg";
import Slide from "../common/uikit/Slide";
import TitleBlock from "../common/uikit/TitleBlock";
import Text from "../common/uikit/Text";

const DisplayWarningSlides = () => {
  const { t } = useTranslation("app");

  return (
    <Slide>
      <div className="px-8 py-8">
        <Attention className="mx-auto w-36 h-36 text-easycheck-bluepurple stroke-none" />
        <TitleBlock p1={t("slide.scratch.p1")} />

        <div className="px-2 py-4 my-8 bg-white rounded ">
          <Text type="Caption A">
            <b>{t("slide.scratch.p2")}</b>
            <p className="inline">{t("slide.scratch.p3")}</p>
          </Text>
        </div>
      </div>
    </Slide>
  );
};

export default DisplayWarningSlides;
