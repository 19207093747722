import Button from "../uikit/Button";

// types
type Props = {
  title: string;
  onChange: any;
  weight?: "primary" | "secondary";
};

// component
function CameraButton(props: Props) {
  return (
    <>
      <span className="hidden xs:block">
        <Button
          asChild
          weight={props.weight}
        >
          <label>
            {props.title}
            <input
              type="file"
              capture="environment"
              accept="image/*"
              className="hidden"
              onChange={props.onChange}
            />
          </label>
        </Button>
      </span>
      <span className="block xs:hidden">
        <Button
          asChild
          weight={props.weight}
        >
          <label>
            {props.title}
            <input
              type="file"
              capture="environment"
              accept="image/*"
              className="hidden"
              onChange={props.onChange}
            />
          </label>
        </Button>
      </span>
    </>
  );
}

export default CameraButton;
