import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as CheckCircle } from "../assets/icons/icon-checkCircle.svg";
import TitleBlock from "../components/common/uikit/TitleBlock";
import { ErrorCode } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { setInvocationIsValid } from "../redux/modules/app";
import { setErrorCode } from "../redux/modules/error";

import { ReactComponent as Logo } from "../assets/logos/startScreenLogo.svg";

const Success = () => {
  const { t } = useTranslation("app");
  const dispatch = useDispatch();
  const history = useHistory();

  useInvocationCheck();

  useEffect(() => {
    return () => {
      dispatch(setInvocationIsValid(false));
      dispatch(setErrorCode(ErrorCode.InvalidInvocation));
      history.replace("/app");
    };
  }, []);

  return (
    <div className="text-white">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
      </Helmet>
      <div className="flex flex-col text-center bg-white h-dvh">
        <div className="flex flex-col items-center justify-center px-8 grow bg-easycheck-success-green rounded-b-xl">
          <CheckCircle className="w-12 h-12 mx-auto" />
          <TitleBlock
            p1={t("success.title")}
            p2={t("success.body2")}
            textClassName="text-white"
            className="mt-6"
          />
        </div>
        <div className="flex flex-col items-center justify-center p-10">
          <Logo className="h-12" />
          <p className="mt-1 italic font-light text-easycheck-blue">permanent easy</p>
        </div>
      </div>
    </div>
  );
};

export default Success;
