import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Code from "../components/common/Code";
import ButtonTab from "../components/common/uikit/ButtonTab";
import Text from "../components/common/uikit/Text";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";

const BigCode = () => {
  const { t } = useTranslation("app");
  const history = useHistory();

  const { qrcode } = useSelector((state: RootState) => {
    return {
      qrcode: state.user.originalURL,
    };
  });

  useInvocationCheck();

  const onPrevButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const onNextButtonClick = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <div className="flex flex-col px-8 text-center bg-white min-h-dvh">
      <div className="px-8 mt-8">
        <Text type="Headline M">{t("troubleshoot.qrcode.p1")}</Text>
      </div>

      <div className="flex flex-col justify-center mb-16 grow">
        <Code url={qrcode} />
      </div>

      <ButtonTab
        backButton_onClick={onPrevButtonClick}
        nextButton_onClick={onNextButtonClick}
        nextButton_children={t("button.done_next")}
        nextButton_show={false}
      />
    </div>
  );
};

export default BigCode;
