import { Transition } from "@headlessui/react";
import {
  ConnectivityResponse,
  ConnectivityStatus,
  DecoderResponseError,
  DecoderResponseSuccess,
  DevicePositionResponse,
  DevicePositionStatus,
  PrismaSDK,
  Subscription,
} from "@prismadelabs/prismaid";
import anime from "animejs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import SDKSingleton from "../../SDK";
import { LicenseType } from "../../enums";
import { GA } from "../../helpers/GA/ga";
import { RootState } from "../../redux";
import {
  SwipeResult,
  addSwipeResult,
  setCount,
  setNetworkStatus,
  setProgress,
  setShowProcessIndicator,
  setShowDot,
  setShowSwipeResults,
  setSwipeResults,
  setTransactionData,
} from "../../redux/modules/swipe";
import { setCanRetry } from "../../redux/modules/user";
import ScaledImage from "../common/ScaledImage";
import Sound from "../common/Sound";
import UnScaledImage from "../common/UnScaledImage";

import whiteFrame from "../../assets/swipe/swipeback_white@2x.png";

import paperFull from "../../assets/swipe/paper_full@2x.png";
import paperFrame from "../../assets/swipe/swipeback_paper@2x.png";

import grayFull from "../../assets/swipe/gray_full@2x.png";
import grayFrame from "../../assets/swipe/swipeback_gray@2x.png";

import plasticFrame from "../../assets/swipe/swipeback_plastic@2x.png";

import a from "../../assets/swipe/Point_A.png";
import b from "../../assets/swipe/Point_B.png";
import paperEdge from "../../assets/swipe/paper-edge@2x.png";

import plasticHologram from "../../assets/swipe/dl-hologram@2x.png";
import { ReactComponent as Riffellinien } from "../../assets/swipe/riffellinien.svg";
import pfeilMarker from "../../assets/swipe/swipe-escalator-erweiterung@2x.png";

import touchPrint from "../../assets/swipe/touch_fingerabdruck@2x.png";

import crystalAlert from "../../assets/sounds/crystal_alert.mp3";
import helpAlert from "../../assets/sounds/ding.mp3";
import { TouchRecord } from "@prismadelabs/prismaid/dist/model/TouchRecord";

// types
const mapStateToProps = (state: RootState) => ({
  licenseType: state.user.licenseType,
  mode: state.user.mode,
  callbackUrl: state.user.callbackUrl,
  cancelUrl: state.user.cancelUrl,
  driverId: state.user.driverId,
  count: state.swipe.count,
  showDot: state.swipe.showDot,
  showProcessIndicator: state.swipe.showProcessIndicator,
  showSwipeResults: state.swipe.showSwipeResults,
  swipeResults: state.swipe.swipeResults,
  transactionData: state.swipe.transactionData,
  transactionDataSignature: state.swipe.transactionDataSignature,
  scaleFactor: state.swipe.scaleFactor,
});

const mapDispatchToProps = {
  setNetworkStatus,
  setProgress,
  setShowDot,
  setShowProcessIndicator,
  setShowSwipeResults,
  setCount,
  setTransactionData,
  addSwipeResult,
  setSwipeResults,
  setCanRetry,
};

type SwipeFieldProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation & RouteComponentProps;

type SwipeFieldStates = {
  isRedirecting: boolean;
};

// component
class SwipeField extends React.Component<SwipeFieldProps, SwipeFieldStates> {
  sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
  private successSound: Sound;
  private errorSound: Sound;

  private progressSubject: Subscription | undefined;
  private sendDataSubject: Subscription | undefined;
  private connectivitySubject: Subscription | undefined;
  private detectionSuccessSubject: Subscription | undefined;
  private detectionErrorSubject: Subscription | undefined;
  private interactionSubject: Subscription | undefined;
  private interactiveHelperFlashSubject: Subscription | undefined;
  private interactiveHelperScreenSubject: Subscription | undefined;

  constructor(props: SwipeFieldProps) {
    super(props);

    this.state = {
      isRedirecting: false,
    };

    this.successSound = new Sound(crystalAlert);
    this.errorSound = new Sound(helpAlert);

    this.props.setNetworkStatus(ConnectivityStatus.ok);

    this.sdk = this.prepareSDK();
  }

  componentDidMount() {
    this.initialisePrismaSDK();
  }

  componentWillUnmount() {
    this.progressSubject?.unsubscribe();
    this.sendDataSubject?.unsubscribe();
    this.connectivitySubject?.unsubscribe();
    this.detectionSuccessSubject?.unsubscribe();
    this.detectionErrorSubject?.unsubscribe();
    this.interactionSubject?.unsubscribe();
    this.interactiveHelperFlashSubject?.unsubscribe();
    this.interactiveHelperScreenSubject?.unsubscribe();

    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  prepareSDK() {
    this.sdk.setScreenResolutionScaleFactor(this.props.scaleFactor);
    this.sdk.setHoldingThumbArea(241, 259, -80, 400); // unscaled pixel values of actualImageHeight, actualImageWidth, marginLeft, marginBottom

    this.progressSubject = this.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      if (response.progress > 10 && response.progress < 20) {
        this.props.setShowSwipeResults(false);
      }

      this.props.setProgress(response.progress);
    });

    this.sendDataSubject = this.sdk.getDetection().sendDataSubject.subscribe((response: TouchRecord[]) => {
      console.log("*) sendDataSubject response:", response);

      if (response.some((touchRecord) => touchRecord.event === "end")) {
        this.props.setShowProcessIndicator(true);
        this.props.setShowSwipeResults(false);
      }
    });

    this.connectivitySubject = this.sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      this.props.setNetworkStatus(response.status);

      switch (response.status) {
        case ConnectivityStatus.slow:
          new GA().trackEvent(window, "network_slow");
          break;

        case ConnectivityStatus.offline:
          new GA().trackEvent(window, "network_offline");
          break;
      }
    });

    return this.sdk;
  }

  initialisePrismaSDK = () => {
    this.detectionSuccessSubject = this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) nextScreen:", response.rawData);
      new GA().trackEvent(window, "swipescreen_incoming_response_success");

      this.props.setCount(this.props.count + 1);

      this.processResponse(response);
    });

    this.detectionErrorSubject = this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      new GA().trackEvent(window, "swipescreen_incoming_response_failure");

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      this.processResponse(response);
    });

    this.interactionSubject = this.sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          this.props.setShowDot(true);
          break;
        case "complete":
          this.props.setShowDot(false);
          break;

        default:
          break;
      }
    });

    this.interactiveHelperScreenSubject = this.sdk.getInteractiveHelperScreenSubject().subscribe((response) => {
      console.log("%c*) interactiveHelperScreen event:", "color: white; background-color: red;", response.nextScreen);
      setTimeout(() => {
        new GA().trackInteractiveHelpScreen(window, response.nextScreen.screenId);

        if (response.nextScreen.screenId === "failed") {
          console.warn("nextScreen: failure");

          this.props.history.push("/failure");
          return;
        } else if (response.nextScreen.screenId === "other_device") {
          setCanRetry(true);

          this.props.history.push("/failure");
          return;
        } else {
          if (!this.props.showProcessIndicator) {
            this.updateMessages({
              type: "error",
              text: this.props.t("interactiveHelp:" + response.nextScreen.screenId),
            });
          }
        }
      }, 50);
    });

    this.sdk.getDevicePositionSubject().subscribe((response: DevicePositionResponse) => {
      console.log("*) DevicePosition event:", response.status);

      if (response.status === DevicePositionStatus.ontable) {
        this.updateMessages({
          type: "error",
          text: "interactiveHelp:device_in_hand",
        });
      }
      if (response.status === DevicePositionStatus.inhand) {
        this.props.setShowSwipeResults(false);
      }
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.sdk.attachToElement(screen);
    }
    this.setCustomPayload();

    setTimeout(() => {
      this.startDemoAnimation();
    }, 1000);
  };

  unsubscribeFromIH = () => {
    this.interactiveHelperFlashSubject?.unsubscribe();
    this.interactiveHelperScreenSubject?.unsubscribe();
  };

  processResponse = (response: DecoderResponseError | DecoderResponseSuccess) => {
    // bail early if page is already redirecting
    if (this.state.isRedirecting) return;

    this.props.setShowProcessIndicator(false);

    switch (response.rawData.nextScreen) {
      case "success":
        this.props.setShowSwipeResults(false);
        this.setState({
          isRedirecting: true,
        });

        this.unsubscribeFromIH();

        new GA().trackEvent(window, "swipescreen_success_done");

        this.successSound.play();

        this.props.history.push("/success");
        break;

      case "help":
      case "swipe":
        this.props.setTransactionData({
          transactionData: response.rawData.transactionData,
          transactionDataSignature: response.rawData.transactionDataSignature,
        });
        this.setCustomPayload();

        this.props.setCount(this.props.count + 1);

        // error
        if (response instanceof DecoderResponseError) {
          let hintCode = "";
          // filter for handled error codes
          let codes = response.hints.filter((hint) => {
            return (
              hint.code === "card_unstable" ||
              hint.code === "invalid_signal" ||
              hint.code === "swipe_faster" ||
              hint.code === "swipe_slower" ||
              hint.code === "swipe_without_card"
            );
          });

          if (codes.length > 0) {
            hintCode = codes[0].code;
          }

          switch (hintCode) {
            case "card_unstable":
            case "invalid_signal":
            case "swipe_faster":
            case "swipe_slower":
            case "swipe_without_card":
              this.errorSound.play();
              this.updateMessages({
                type: "error",
                text: this.props.t("swipe:" + hintCode + ".body"),
              });

              break;
            default:
              // unknown code or ""
              this.errorSound.play();
              this.updateMessages({
                type: "error",
                text: this.props.t("swipe:retry.body"),
              });
              break;
          }
          new GA().trackEvent(window, "swipescreen_error");
        } else {
          // success
          new GA().trackEvent(window, "swipescreen_success_again");
          this.errorSound.play();
          this.updateMessages({
            type: "error",
            text: this.props.t("swipe:retry.body"),
          });
        }
        break;

      case "failure_hard":
        // possible fraud
        this.props.setShowSwipeResults(false);
        this.setState({
          isRedirecting: true,
        });
        this.unsubscribeFromIH();
        new GA().trackEvent(window, "swipescreen_failure_final");
        this.errorSound.play();
        this.props.setCanRetry(false);
        this.props.history.push("/failure");
        break;

      default:
        // nextScreen: "failure"
        this.props.setShowSwipeResults(false);
        this.setState({
          isRedirecting: true,
        });
        this.unsubscribeFromIH();
        this.errorSound.play();
        new GA().trackEvent(window, "swipescreen_failure_final");
        this.props.history.push("/failure");
        break;
    }
  };

  setCustomPayload = () => {
    this.sdk.setCustomPayload({
      transactionData: this.props.transactionData,
      transactionDataSignature: this.props.transactionDataSignature,
      signature: "123:7dktz4hxqgx39gsh634gt82n78dfgf8",
      mode: this.props.mode,
      callbackUrl: this.props.callbackUrl,
      cancelUrl: this.props.cancelUrl,
      licenseType: this.props.licenseType,
      driverId: this.props.driverId,
    });
  };

  updateMessages = (result: SwipeResult) => {
    this.props.addSwipeResult(result);
    this.props.setShowSwipeResults(true);
  };

  startDemoAnimation = () => {
    anime({
      targets: "#hologram",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#hologram",
      scale: 1.8,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: "#edge",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#edge",
      scale: 1.2,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    setTimeout(() => {
      anime({
        targets: "#edge",
        opacity: 0.5,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#edge",
            opacity: 1.0,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 2000);

    setTimeout(() => {
      anime({
        targets: "#touchPrint",
        opacity: 0.6,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#touchPrint",
            opacity: 0.4,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 1000);

    setTimeout(() => {
      anime({
        targets: ["#riffelcontainer_white"],
        opacity: 1.0,
        duration: 1000,
        easing: "linear",
      });
    }, 3000);

    setTimeout(() => {
      anime({
        targets: "#riffelcontainer_blue",
        height: ["0px", this.props.scaleFactor * 1010 + "px"],
        duration: 1500,
        delay: 1000,
        endDelay: 2000,
        loop: true,
        easing: "linear",
      });
    }, 4000);
  };

  render() {
    let swipeField;

    if (this.props.licenseType === LicenseType.Gray) {
      swipeField = (
        <>
          <ScaledImage
            src={grayFull}
            id="gray"
            alt="gray"
            horizontalAlign="left"
            verticalAlign="bottom"
            verticalOffset={1598}
            className="mb-2 ml-2 opacity-25"
          />

          <ScaledImage
            src={whiteFrame}
            id=""
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />
          <ScaledImage
            src={grayFrame}
            id="frame"
            alt="grayFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />

          <ScaledImage
            src={paperEdge}
            id="edge"
            alt="grayEdge"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
            verticalOffset={-900}
            opacity={0}
          />
        </>
      );
    } else if (this.props.licenseType === LicenseType.Paper) {
      swipeField = (
        <>
          <ScaledImage
            src={paperFull}
            id="paper"
            alt="paper"
            horizontalAlign="left"
            verticalAlign="bottom"
            verticalOffset={262}
            className="mb-2 ml-2 opacity-25"
          />

          <ScaledImage
            src={whiteFrame}
            id=""
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />
          <ScaledImage
            src={paperFrame}
            id="frame"
            alt="paperFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />

          <ScaledImage
            src={paperEdge}
            id="edge"
            alt="paperEdge"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
            verticalOffset={-900}
            opacity={0}
          />
        </>
      );
    } else {
      swipeField = (
        <>
          <ScaledImage
            src={whiteFrame}
            id=""
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2 rounded-[18px]"
          />
          <ScaledImage
            src={plasticFrame}
            id="frame"
            alt="plasticFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />
          <ScaledImage
            src={plasticHologram}
            id="hologram"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={430}
            verticalOffset={-78}
            opacity={0}
          />
        </>
      );
    }

    return (
      <>
        <Transition
          show={!this.props.showDot && !this.props.showProcessIndicator}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute top-0 left-0 w-screen h-dvh bg-stripes-red"></div>
        </Transition>
        <div className="absolute left-0 w-screen h-dvh bottom-4">
          {swipeField}

          <ScaledImage
            src={touchPrint}
            id="touchPrint"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={-80}
            verticalOffset={-400}
            opacity={0}
          />

          <Transition
            as="div"
            show={this.props.showDot}
            enter="transition-opacity duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ScaledImage
              src={pfeilMarker}
              id="markerTop"
              alt=""
              horizontalAlign="left"
              verticalAlign="bottom"
              horizontalOffset={426}
              verticalOffset={-1114}
            />

            <ScaledImage
              src={pfeilMarker}
              id="markerBottom"
              alt=""
              horizontalAlign="left"
              verticalAlign="bottom"
              horizontalOffset={426}
              verticalOffset={4}
            />
          </Transition>

          <UnScaledImage
            src={a}
            id="A"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={456}
            verticalOffset={0}
          />
          <UnScaledImage
            src={b}
            id="B"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={447}
            verticalOffset={-1150}
          />

          <div
            className="absolute overflow-hidden"
            style={{
              width: this.props.scaleFactor * 200 + "px",
              height: this.props.scaleFactor * 1010 + "px",
              left: this.props.scaleFactor * 375 + "px",
              bottom: this.props.scaleFactor * 50 + "px",
              opacity: 0,
            }}
            id="riffelcontainer_white"
          >
            <Riffellinien
              className="absolute bottom-0 left-0 w-full h-auto text-white"
              id="riffel_white"
            />
          </div>
          <div
            className="absolute overflow-hidden"
            style={{
              width: this.props.scaleFactor * 200 + "px",
              height: "0px",
              left: this.props.scaleFactor * 375 + "px",
              bottom: this.props.scaleFactor * 50 + "px",
            }}
            id="riffelcontainer_blue"
          >
            <Riffellinien
              className="absolute bottom-0 left-0 w-full h-auto text-easycheck-bluepurple"
              id="riffel_blue"
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SwipeField));
