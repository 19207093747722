import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_de from "./translations/de/common.json";
import cookies_de from "./translations/de/cookies.json";
import invalid_de from "./translations/de/invalid.json";
import welcome_de from "./translations/de/welcome.json";
import swipe_de from "./translations/de/swipe.json";
import select_de from "./translations/de/select.json";
import touch_de from "./translations/de/touch.json";
import failure_de from "./translations/de/failure.json";
import interactiveHelp_de from "./translations/de/interactiveHelp.json";
import demo_de from "./translations/de/demo.json";
import upload_de from "./translations/de/upload.json";
import app_de from "./translations/de/app.json";

import common_en from "./translations/en/common.json";
import cookies_en from "./translations/en/cookies.json";
import invalid_en from "./translations/en/invalid.json";
import welcome_en from "./translations/en/welcome.json";
import swipe_en from "./translations/en/swipe.json";
import select_en from "./translations/en/select.json";
import touch_en from "./translations/en/touch.json";
import failure_en from "./translations/en/failure.json";
import interactiveHelp_en from "./translations/en/interactiveHelp.json";
import demo_en from "./translations/en/demo.json";
import upload_en from "./translations/en/upload.json";
import app_en from "./translations/en/app.json";

const resources = {
  en: {
    common: common_en,
    cookies: cookies_en,
    invalid: invalid_en,
    welcome: welcome_en,
    swipe: swipe_en,
    select: select_en,
    touch: touch_en,
    failure: failure_en,
    interactiveHelp: interactiveHelp_en,
    demo: demo_en,
    upload: upload_en,
    app: app_en,
  },
  de: {
    common: common_de,
    cookies: cookies_de,
    invalid: invalid_de,
    welcome: welcome_de,
    swipe: swipe_de,
    select: select_de,
    touch: touch_de,
    failure: failure_de,
    interactiveHelp: interactiveHelp_de,
    demo: demo_de,
    upload: upload_de,
    app: app_de,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    lng: "en",
    supportedLngs: ["en", "de"],
    fallbackLng: "en",

    fallbackNS: "common",

    // debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "b", "strong", "i", "p", "u"],
    },
  });

export default i18n;
