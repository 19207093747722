import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";
import Text from "./Text";

type ButtonProps = {
  size?: "default" | "small" | "medium";
  weight?: "primary" | "secondary";
  asChild?: boolean;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const baseButtonClasses = "text-center rounded-full w-auto h-auto group";
const buttonClasses: Record<NonNullable<ButtonProps["weight"]>, string> = {
  primary: "bg-easycheck-bluepurple active:bg-easycheck-blue disabled:bg-easycheck-blue/50",
  secondary: "bg-easycheck-shade-light active:bg-easycheck-shade-medium disabled:bg-easycheck-shade-light/50",
};
const buttonSizeClasses: Record<NonNullable<ButtonProps["size"]>, string> = {
  default: "py-3 px-9",
  small: "py-3 px-8",
  medium: "py-2 px-6",
};

const baseTextClasses = "select-none";

const textClasses: Record<NonNullable<ButtonProps["weight"]>, string> = {
  primary: "text-white group-enabled:group-active:text-easycheck-shade-light group-disabled:text-easycheck-blue/40",
  secondary: "text-easycheck-bluepurple group-enabled:group-active:text-easycheck-shade-dark group-disabled:text-easycheck-blue/40",
};

const textSizeClasses: Record<NonNullable<ButtonProps["size"]>, string> = {
  default: "Headline M",
  small: "Body",
  medium: "Caption C",
};

// TODO sound onClick
const Button = ({ size = "default", weight = "primary", asChild, disabled, children, ...restProps }: ButtonProps) => {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      {...restProps}
      className={classNames(baseButtonClasses, buttonClasses[weight], buttonSizeClasses[size])}
      disabled={disabled}
    >
      <Text
        type={textSizeClasses[size] as any}
        className={classNames(baseTextClasses, textClasses[weight])}
      >
        {children}
      </Text>
    </Comp>
  );
};

export default Button;
