import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Block from "../components/common/uikit/Block";
import Button from "../components/common/uikit/Button";
import Text from "../components/common/uikit/Text";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";

import image from "../assets/welcome/welcome.jpg";

export const Welcome = () => {
  const history = useHistory();
  const { t } = useTranslation("app");

  useInvocationCheck();

  const { licenseIsKnown, greeting, daysLeft } = useSelector((state: RootState) => {
    return {
      greeting: state.user.greeting,
      daysLeft: state.user.daysLeft,
      licenseIsKnown: state.user.licenseIsKnown,
    };
  });

  const onNextButtonClick = useCallback(() => {
    if (licenseIsKnown) {
      history.push("/onboarding");
    } else {
      history.push("/select");
    }
  }, [licenseIsKnown, history]);

  return (
    <>
      <div className="flex flex-col justify-between px-8 py-4 text-center min-h-dvh ">
        <div className="absolute inset-0 w-screen h-dvh -z-40">
          <img
            src={image}
            alt="smartphone showing the easycheck logo"
            className="absolute inset-x-0 bottom-0 object-fill object-bottom w-screen h-auto"
          />
        </div>
        <div className="mt-8">
          <span className="hidden xs:inline">
            <Text type="Body">
              {t("welcome.p1")}
              {greeting}
            </Text>
            <Text
              type="Headline L"
              className="mt-1"
            >
              {t("welcome.p2")}
            </Text>
            {daysLeft && (
              <div className="mt-4">
                <Block>{t("welcome.p4", { days: daysLeft })}</Block>
              </div>
            )}
          </span>
          <span className="inline xs:hidden">
            <Text type="Caption A">
              {t("welcome.p1")}
              {greeting}
            </Text>
            <Text
              type="Headline M small"
              className="mt-1"
            >
              {t("welcome.p2")}
            </Text>
            {daysLeft && (
              <div className="mt-4">
                <Block size="small">{t("welcome.p4", { days: daysLeft })}</Block>
              </div>
            )}
          </span>
        </div>

        <div className="flex items-center justify-center px-8">
          <span className="hidden xs:inline">
            <Button onClick={onNextButtonClick}>{t("button.start")}</Button>
          </span>
          <span className="xs:hidden">
            <Button
              onClick={onNextButtonClick}
              size="small"
            >
              {t("button.start")}
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};

export default Welcome;
