import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";

type TextProps = {
  type: "Headline XL" | "Headline L" | "Headline M" | "Headline M small" | "Headline S" | "Headline S small" | "Body" | "Caption A" | "Caption B" | "Caption C";
  asChild?: boolean;
  className?: string;
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: { __html: string };
};

const baseClasses = "text-easycheck-bluepurple text-balance whitespace-pre-wrap";

const sizeClasses: Record<NonNullable<TextProps["type"]>, string> = {
  "Headline S": "text-[1.125rem]/[1.25rem] font-light",
  "Headline S small": "text-[1rem] font-light",
  "Headline M": "font-medium text-[1.125rem]/[1.375rem]",
  "Headline M small": "font-medium text-[1rem]",
  "Headline L": "font-bold text-[1.5rem]/[1.875rem]",
  "Headline XL": "font-bold text-[1.875rem]/[2rem]",
  Body: "text-[1rem]/[1.375rem] font-normal",
  "Caption A": "font-normal text-[0.875rem]/[1.125rem]",
  "Caption B": "font-medium text-[0.6875rem] uppercase",
  "Caption C": "font-medium text-[0.8125rem] uppercase",
};

const Text = ({ type, asChild, className, children, dangerouslySetInnerHTML, ...restProps }: TextProps) => {
  const Comp = asChild ? Slot : "div";

  if (dangerouslySetInnerHTML)
    return (
      <Comp
        {...restProps}
        className={classNames(baseClasses, sizeClasses[type], className)}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      ></Comp>
    );

  return (
    <Comp
      {...restProps}
      className={classNames(baseClasses, sizeClasses[type], className)}
    >
      {children}
    </Comp>
  );
};

export default Text;
