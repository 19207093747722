import { typedAction } from "../helpers";

type OnboardingState = {
  q_oldPhone: string;
  q_zoomActivated: string;
};

const initialState: OnboardingState = {
  q_oldPhone: "",
  q_zoomActivated: "",
};

export const setChecklistData = (checklistData: { q_oldPhone: string; q_zoomActivated: string }) => {
  return typedAction("onboarding/SET_CHECKLISTDATA", checklistData);
};
export const set_q_oldPhone = (q_oldPhone: string) => {
  return typedAction("onboarding/SET_q_oldPhone", q_oldPhone);
};
export const set_q_zoomActivated = (q_zoomActivated: string) => {
  return typedAction("onboarding/SET_q_zoomActivated", q_zoomActivated);
};

type OnboardingAction = ReturnType<typeof setChecklistData | typeof set_q_oldPhone | typeof set_q_zoomActivated>;

export function onboardingReducer(state = initialState, action: OnboardingAction): OnboardingState {
  switch (action.type) {
    case "onboarding/SET_CHECKLISTDATA":
      return {
        ...state,
        q_oldPhone: action.payload.q_oldPhone,
        q_zoomActivated: action.payload.q_zoomActivated,
      };
    case "onboarding/SET_q_oldPhone":
      return {
        ...state,
        q_oldPhone: action.payload,
      };
    case "onboarding/SET_q_zoomActivated":
      return {
        ...state,
        q_zoomActivated: action.payload,
      };
    default:
      return state;
  }
}
