import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ButtonTab from "../components/common/uikit/ButtonTab";
import ProcessIndicator from "../components/common/uikit/ProcessIndicator";
import DisplayWarningSlides from "../components/onboarding/DisplayWarningSlides";
import TouchSettingsSlides from "../components/onboarding/TouchSettingsSlides";
import TutorialSlides from "../components/onboarding/TutorialSlides";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";

type PathParams = {
  slug: string;
};

const slugs = ["prepare", "place_card", "place_fingers", "swipe", "display", "touch_sensitivity"];

// position starts at 1
// index starts at 0
const getPositionFromIndex = (index: number) => index + 1;
const getIndexFromPosition = (position: number) => position - 1;

const getPositionFromSlug = (slug: string) => {
  return getPositionFromIndex(slugs.findIndex((value) => slug === value));
};
const getSlugFromPosition = (position: number) => {
  return slugs[getIndexFromPosition(position)];
};

export const Onboarding = () => {
  const history = useHistory();
  const { t } = useTranslation("app");

  const { slug } = useParams<PathParams>();

  const { licenseIsKnown } = useSelector((state: RootState) => {
    return {
      licenseIsKnown: state.user.licenseIsKnown,
    };
  });

  useInvocationCheck();

  const [emblaRef, emblaApi] = useEmblaCarousel();

  const [current, setCurrent] = useState(getPositionFromSlug(slug));
  const [count, setCount] = useState(1);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (current === 1) {
      if (licenseIsKnown) {
        history.replace("/welcome");
      } else {
        history.replace("/select");
      }
    } else {
      emblaApi.scrollPrev();
    }
  }, [emblaApi, current, history, licenseIsKnown]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (current === count) {
      history.push("/checklist");
    } else {
      emblaApi.scrollNext();
    }
  }, [emblaApi, current, count, history]);

  useEffect(() => {
    setCurrent(getPositionFromSlug(slug));
    let expected = getIndexFromPosition(getPositionFromSlug(slug));

    if (!emblaApi) return;
    let selected = emblaApi?.selectedScrollSnap();
    if (selected !== expected) {
      console.warn("scrolling from ", selected, " to ", expected);
      emblaApi?.scrollTo(expected);
    }
  }, [emblaApi, slug]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setCount(emblaApi.scrollSnapList().length);
    setCurrent(getPositionFromIndex(emblaApi.selectedScrollSnap()));

    emblaApi.on("select", () => {
      let currentSlide = getPositionFromIndex(emblaApi.selectedScrollSnap());
      setCurrent(currentSlide);
      history.push(`/onboarding/${getSlugFromPosition(currentSlide)}`);
    });
  }, [emblaApi, history]);

  return (
    <div className="flex flex-col text-center min-h-dvh bg-easycheck-light-blue">
      <div className="px-8 topSpacing">
        {/* <div className="mt-4"> */}
        <span className="hidden xs:block">
          <ProcessIndicator
            current={current}
            count={count}
          />
        </span>
        <span className="xs:hidden">
          <ProcessIndicator
            current={current}
            count={count}
            size="small"
          />
        </span>
      </div>

      <div
        className="flex flex-col mt-4 overflow-hidden grow"
        ref={emblaRef}
      >
        <div className="flex grow">
          <TutorialSlides />
          <DisplayWarningSlides />
          <TouchSettingsSlides />
        </div>
      </div>

      <ButtonTab
        backButton_onClick={onPrevButtonClick}
        nextButton_onClick={onNextButtonClick}
        nextButton_children={t("button.next")}
      />
    </div>
  );
};

export default Onboarding;
