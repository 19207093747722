import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Check } from "../assets/icons/icon-check.svg";
import CenteredLayout from "../components/common/layouts/CenteredLayout";
import TitleBlock from "../components/common/uikit/TitleBlock";
import { ErrorCode } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { setInvocationIsValid } from "../redux/modules/app";
import { setErrorCode } from "../redux/modules/error";

function UploadSuccess() {
  const { t } = useTranslation("upload");
  const dispatch = useDispatch();
  const history = useHistory();

  useInvocationCheck();

  useEffect(() => {
    return () => {
      dispatch(setInvocationIsValid(false));
      dispatch(setErrorCode(ErrorCode.InvalidInvocation));
      history.replace("/upload");
    };
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
      </Helmet>
      <div className="absolute top-0 w-screen overflow-hidden text-center h-dvh">
        <CenteredLayout>
          <Check className="w-6 h-6 mx-auto text-easycheck-success-green" />

          <div className="mt-4">
            <TitleBlock
              p1={t("submit.p1")}
              p2={t("submit.p2")}
            />
          </div>
        </CenteredLayout>
      </div>
    </div>
  );
}

export default UploadSuccess;
