import { typedAction } from "../helpers";
import { LicenseType } from "../../enums";

export type UserState = {
  greeting: string;
  // TODO not optional?
  daysLeft?: string;
  mode: string;
  callbackUrl: string;
  licenseType: LicenseType | null;
  originalURL: string;
  cancelUrl: string;
  canRetry: boolean;
  // optional
  licenseIsKnown?: boolean;
  eventUrl?: string;
  uploadUrl?: string;
  driverId?: string;
  quizNumber?: string;
  skipUpload?: boolean;
};

const initialState: UserState = {
  greeting: "",
  daysLeft: "",
  mode: "",
  callbackUrl: "",
  licenseType: 0,
  originalURL: "",
  cancelUrl: "",
  canRetry: false,
  // optional
  licenseIsKnown: false,
  eventUrl: "",
  uploadUrl: "",
  driverId: "",
  quizNumber: "",
  skipUpload: false,
};

export const resetData = () => {
  return typedAction("user/RESET_DATA");
};

export const setUserData = (userData: {
  greeting: string;
  daysLeft: string;
  mode: string;
  callbackUrl: string;
  originalURL: string;
  canRetry: boolean;
  driverId: string;
  // optional
  licenseType?: LicenseType;
  cancelUrl?: string;
  eventUrl?: string;
  uploadUrl?: string;
}) => {
  return typedAction("user/SET_USERDATA", userData);
};

export const setDriverId = (driverId: string) => {
  return typedAction("user/SET_DRIVERID", driverId);
};
export const setGreeting = (greeting: string) => {
  return typedAction("user/SET_GREETING", greeting);
};
export const setQuizNumber = (quizNumber: string) => {
  return typedAction("user/SET_QUIZNUMBER", quizNumber);
};

export const setLicenseType = (licenseType: LicenseType) => {
  return typedAction("user/SET_CARDTYPE", licenseType);
};
export const setLicenseIsKnown = (licenseIsKnown: boolean) => {
  return typedAction("user/SET_LICENSE_IS_KNOWN", licenseIsKnown);
};

export const setCanRetry = (canRetry: boolean) => {
  return typedAction("user/SET_CANRETRY", canRetry);
};

export const setEventUrl = (eventUrl: string) => {
  return typedAction("user/SET_EVENTURL", eventUrl);
};
export const setUploadUrl = (uploadUrl: string) => {
  return typedAction("user/SET_UPLOADURL", uploadUrl);
};
export const setSkipUpload = (skipUpload: boolean) => {
  return typedAction("user/SET_SKIPUPLOAD", skipUpload);
};

type UserAction = ReturnType<
  | typeof resetData
  | typeof setUserData
  | typeof setDriverId
  | typeof setGreeting
  | typeof setQuizNumber
  | typeof setLicenseType
  | typeof setLicenseIsKnown
  | typeof setCanRetry
  | typeof setEventUrl
  | typeof setUploadUrl
  | typeof setSkipUpload
>;

export function userReducer(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case "user/RESET_DATA":
      return {
        ...initialState,
      };
    case "user/SET_USERDATA":
      return {
        ...state,
        ...action.payload,
      };
    case "user/SET_DRIVERID":
      return {
        ...state,
        driverId: action.payload,
      };
    case "user/SET_GREETING":
      return {
        ...state,
        greeting: action.payload,
      };
    case "user/SET_QUIZNUMBER":
      return {
        ...state,
        quizNumber: action.payload,
      };
    case "user/SET_CARDTYPE":
      return {
        ...state,
        licenseType: action.payload,
      };
    case "user/SET_LICENSE_IS_KNOWN":
      return {
        ...state,
        licenseIsKnown: action.payload,
      };
    case "user/SET_CANRETRY":
      return {
        ...state,
        canRetry: action.payload,
      };
    case "user/SET_EVENTURL":
      return {
        ...state,
        eventUrl: action.payload,
      };
    case "user/SET_UPLOADURL":
      return {
        ...state,
        uploadUrl: action.payload,
      };
    case "user/SET_SKIPUPLOAD":
      return {
        ...state,
        skipUpload: action.payload,
      };
    default:
      return state;
  }
}
