import React from "react";
import { Helmet } from "react-helmet";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import SDKSingleton from "../SDK";
import { ReactComponent as BrokenLink } from "../assets/icons/icon-broken-link.svg";
import { ReactComponent as Warning } from "../assets/icons/icon-warning.svg";
import Code from "../components/common/Code";
import CenteredLayout from "../components/common/layouts/CenteredLayout";
import Button from "../components/common/uikit/Button";
import Text from "../components/common/uikit/Text";
import { ErrorCode } from "../enums";
import i18n from "../i18n";
import { RootState } from "../redux";
import { GA } from "../helpers/GA/ga";

const mapStateToProps = (state: RootState) => ({
  errorCode: state.error.errorCode,
  qrCode: state.user.originalURL,
});

const mapDispatchToProps = {};

type InvalidProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation & RouteComponentProps;

type InvalidStates = {
  redirect: any;
};

type ErrorMessage = {
  icon?: JSX.Element;
  title: string;
  body: string;
  tip1?: string;
  tip2?: string;
  qrcode?: string;
  button?: ErrorButton;
};

type ErrorButton = {
  title: string;
  action: any;
};

// component
class Notification extends React.Component<InvalidProps, InvalidStates> {
  private errorMessage: ErrorMessage;

  constructor(props: InvalidProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    try {
      const sdk = SDKSingleton.getInstance().sdk;
      i18n.changeLanguage(sdk.getFinalSDKLanguage());
    } catch (error) {}

    switch (this.props.errorCode) {
      case ErrorCode.UnsupportedDevice: {
        new GA().trackEvent(window, "error_show_wrong_device");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalid:invalidDevice.title"),
          body: props.t("invalid:invalidDevice.body"),
          tip1: props.t("invalid:invalidDevice.tip1"),
          tip2: props.t("invalid:invalidDevice.tip2"),
          qrcode: this.props.qrCode,
        };
        break;
      }

      case ErrorCode.UnsupportedBrowser: {
        new GA().trackEvent(window, "error_show_wrong_browser");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalid:invalidBrowser.title"),
          body: props.t("invalid:invalidBrowser.body"),
          tip1: props.t("invalid:invalidBrowser.tip1"),
          tip2: props.t("invalid:invalidBrowser.tip2"),
          qrcode: this.props.qrCode,
        };
        break;
      }

      case ErrorCode.ScreenTooSmall: {
        new GA().trackEvent(window, "error_show_screen_too_small");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalid:screenTooSmall.title"),
          body: props.t("invalid:screenTooSmall.body"),
          tip2: props.t("invalid:screenTooSmall.tip2"),
          button: {
            title: props.t("invalid:proceedAnyway"),
            action: this.redirectToApp,
          },
        };
        break;
      }

      case ErrorCode.DisplayZoomEnabled: {
        new GA().trackEvent(window, "error_show_zoom_enabled");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-status-red" />,
          title: props.t("invalid:zoomedDisplay.title"),
          body: props.t("invalid:zoomedDisplay.body"),
          button: {
            title: props.t("invalid:proceedAnyway"),
            action: this.redirectToApp,
          },
        };
        break;
      }

      case ErrorCode.InvalidInvocation: {
        new GA().trackEvent(window, "error_show_invocation_invalid");
        this.errorMessage = {
          icon: <BrokenLink className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalidLink.title"),
          body: props.t("invalidLink.body"),
          tip1: props.t("invalidLink.tip1"),
          tip2: props.t("invalidLink.tip2"),
        };
        break;
      }

      case ErrorCode.SessionExpired: {
        new GA().trackEvent(window, "error_show_session_expired");
        this.errorMessage = {
          icon: <BrokenLink className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("sessionExpired.title"),
          body: props.t("sessionExpired.body"),
        };
        break;
      }

      case ErrorCode.LinkRequested: {
        new GA().trackEvent(window, "error_show_link_requested");
        this.errorMessage = {
          title: props.t("linkRequested.title"),
          body: props.t("linkRequested.body"),
        };
        break;
      }

      case ErrorCode.InvalidUploadDevice: {
        new GA().trackEvent(window, "error_show_wrong_device");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalidUploadDevice.title"),
          body: props.t("invalidUploadDevice.body"),
          tip1: props.t("invalidUploadDevice.tip1"),
          tip2: props.t("invalidUploadDevice.tip2"),
        };
        break;
      }

      case ErrorCode.InvalidUploadLink: {
        new GA().trackEvent(window, "error_show_link_invalid");
        this.errorMessage = {
          icon: <BrokenLink className="p-1 m-1 mx-auto w-11 h-11 text-easycheck-bluepurple" />,
          title: props.t("invalidUploadLink.title"),
          body: props.t("invalidUploadLink.body"),
          tip1: props.t("invalidUploadLink.tip1"),
          tip2: props.t("invalidUploadLink.tip2"),
        };
        break;
      }

      case ErrorCode.UploadIsPending: {
        new GA().trackEvent(window, "error_show_upload_is_pending");
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-status-red" />,
          title: props.t("uploadIsPending.title"),
          body: props.t("uploadIsPending.body"),
        };
        break;
      }

      default: {
        this.errorMessage = {
          icon: <Warning className="p-1 m-1 mx-auto w-11 h-11 text-status-red" />,
          title: props.t("error.title"),
          body: props.t("error.body"),
        };
        break;
      }
    }
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  redirectToApp = () => {
    this.props.history.push("/onboarding");
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const btn = this.errorMessage.button ? (
      <div className="mt-12">
        <Button
          onClick={this.errorMessage.button.action}
          className="w-full mt-6"
        >
          {this.errorMessage.button.title}
        </Button>
      </div>
    ) : (
      ""
    );

    return (
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
        </Helmet>
        <div className="absolute top-0 w-screen overflow-hidden text-center h-dvh">
          <CenteredLayout className="">
            {this.errorMessage.icon}

            <div className="mt-4">
              <span className="hidden xs:block">
                <Text type="Headline L">{this.errorMessage.title}</Text>
              </span>
              <span className="xs:hidden">
                <Text type="Headline M small">{this.errorMessage.title}</Text>
              </span>

              <Text
                type="Caption A"
                className="mt-4 whitespace-pre-wrap"
              >
                {this.errorMessage.body}
              </Text>
            </div>

            {this.errorMessage.tip2 && (
              <div className="max-w-xl p-2 mx-auto my-8 bg-white rounded">
                <Text type="Caption A">
                  {this.errorMessage.tip1 && <b>{this.errorMessage.tip1}</b>}
                  <p className="inline">{this.errorMessage.tip2}</p>
                </Text>

                {this.errorMessage.qrcode && (
                  <div className="w-32 h-32 mx-auto my-8">
                    <Code url={this.errorMessage.qrcode} />
                  </div>
                )}
              </div>
            )}
            {btn}
          </CenteredLayout>
        </div>
      </div>
    );
  }
}

export default withRouter(compose<any>(withTranslation("invalid"), connect(mapStateToProps, mapDispatchToProps))(Notification));
