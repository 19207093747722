export enum LicenseType {
  Plastic = 0,
  Paper = 1,
  Gray = 2,
}

export enum LicenseSide {
  Front1 = "front1",
  Back1 = "back1",
}

export enum ErrorCode {
  UnsupportedDevice,
  UnsupportedBrowser,
  ScreenTooSmall,
  DisplayZoomEnabled,

  InvalidInvocation,
  SessionExpired,
  LinkRequested,

  InvalidScreen,

  InvalidUploadDevice,
  InvalidUploadLink,
  UploadIsPending,
}
