import React, { createRef } from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { Wifi, WifiOff } from "react-feather";
import { ConnectivityStatus } from "@prismadelabs/prismaid";

// types
const mapStateToProps = (state: RootState) => ({
  networkStatus: state.swipe.networkStatus,
});

const mapDispatchToProps = {};

type NetworkIndicatorProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type NetworkIndicatorStates = {};

// component
class NetworkIndicator extends React.Component<
  NetworkIndicatorProps,
  NetworkIndicatorStates
> {
  private containerRef = createRef<HTMLDivElement>();

  constructor(props: NetworkIndicatorProps) {
    super(props);

    const container = this.containerRef.current;
    if (container) {
      disableBodyScroll(container);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const networkStatus = this.props.networkStatus;
    let icon;

    if (networkStatus === ConnectivityStatus.ok) return null;

    if (networkStatus === ConnectivityStatus.slow) {
      icon = <Wifi color="orange" size={40} />;
    } else {
      // offline
      icon = <WifiOff color="red" size={40} />;
    }

    return (
      <div
        ref={this.containerRef}
        className="absolute top-0 flex justify-end w-full p-4"
        style={{
          touchAction: "none",
        }}
      >
        {icon}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkIndicator);
