import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// pages
import Open from "./pages/Open";
import App from "./pages/App";

import Welcome from "./pages/Welcome";
import Select from "./pages/Select";
import Onboarding from "./pages/Onboarding";
import Checklist from "./pages/Checklist";
import Troubleshoot from "./pages/Troubleshoot";
import Ready from "./pages/Ready";
import Swipe from "./pages/Swipe";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Notification from "./pages/Notification";

import Upload from "./pages/Upload";
import UploadStart from "./pages/UploadStart";
import UploadSelect from "./pages/UploadSelect";
import UploadFrontPlace from "./pages/UploadFrontPlace";
import UploadFrontTask from "./pages/UploadFrontTask";
import UploadFrontConfirm from "./pages/UploadFrontConfirm";
import UploadBackConfirm from "./pages/UploadBackConfirm";
import UploadBackPlace from "./pages/UploadBackPlace";
import UploadSuccess from "./pages/UploadSuccess";

import BigCode from "./pages/BigCode";
import TroubleshootPhone from "./pages/TroubleshootPhone";
import TroubleshootZoom from "./pages/TroubleshootZoom";
import TroubleshootPhoneAndZoom from "./pages/TroubleshootPhoneAndZoom";
import CookieBanner from "./components/cookies/CookieBanner";

type RouterComponentSpecificProps = {
  history: any;
  location: any;
  match: any;
};

class RouterComponent extends React.Component<RouterComponentSpecificProps> {
  getTransitions = (path: string) => {
    switch (path) {
      case "/open":
      case "/demo":
      case "/":
        return "";
      case "/swipe":
        return "slideFromRight";
      default:
        return "fadeIn";
    }
  };
  getKey = (path: string) => {
    switch (path) {
      case "/open":
      case "/demo":
      case "/":
      case "/error":
        return "";
      case "/onboarding":
      case "/onboarding/prepare":
      case "/onboarding/place_card":
      case "/onboarding/place_fingers":
      case "/onboarding/swipe":
      case "/onboarding/display":
      case "/onboarding/touch_sensitivity":
        return "/onboarding";
      default:
        return path;
    }
  };

  render() {
    return (
      <Route
        render={({ location }) => (
          <div className="touch-none">
            <TransitionGroup>
              <CSSTransition
                key={this.getKey(location.pathname)}
                classNames={this.getTransitions(location.pathname)}
                timeout={600}
              >
                <Switch location={location}>
                  <Route
                    path="/open"
                    component={Open}
                  />
                  <Route
                    exact
                    path="/"
                    component={App}
                  />
                  <Route
                    path="/welcome"
                    component={Welcome}
                  />
                  <Route
                    path="/select"
                    component={Select}
                  />
                  <Redirect
                    path="/onboarding"
                    to="/onboarding/prepare"
                    exact
                  />
                  <Route
                    path="/onboarding/:slug"
                    component={Onboarding}
                  />
                  <Route
                    path="/checklist"
                    component={Checklist}
                  />
                  <Route
                    path="/troubleshoot"
                    component={Troubleshoot}
                    exact
                  />
                  <Route
                    path="/troubleshoot/phone"
                    component={TroubleshootPhone}
                    exact
                  />
                  <Route
                    path="/troubleshoot/zoom"
                    component={TroubleshootZoom}
                    exact
                  />
                  <Route
                    path="/troubleshoot/phoneAndZoom"
                    component={TroubleshootPhoneAndZoom}
                    exact
                  />
                  <Route
                    path="/ready"
                    component={Ready}
                  />
                  <Route
                    path="/swipe"
                    component={Swipe}
                  />
                  <Route
                    path="/success"
                    component={Success}
                  />
                  <Route
                    path="/failure"
                    component={Failure}
                  />
                  <Route
                    path="/code"
                    component={BigCode}
                  />

                  <Route
                    path="/error"
                    component={Notification}
                  />

                  <Redirect
                    path="/demo"
                    to="/open?greeting=Erika%20Mustermann&mode=A028D&callbackUrl=https%3A%2F%2Fprismade.com&originalURL=https%3A%2F%2Fprismade.com&eventUrl=https%3A%2F%2Fcalendly.com%2Feasycheck-by-edding%2Ffsk&uploadUrl=%2Fdemou1&canRetry=true&driverId=12345&cancelUrl=https%3A%2F%2Fprismade.com&checkUntil=13-07-2024"
                  />
                  <Redirect
                    path="/demo2"
                    to="/open?greeting=Erika%20Mustermann&mode=A028D&licenseType=0&callbackUrl=https%3A%2F%2Fprismade.com&originalURL=https%3A%2F%2Fprismade.com&eventUrl=https%3A%2F%2Fcalendly.com%2Feasycheck-by-edding%2Ffsk&uploadUrl=%2Fdemou1&canRetry=false&driverId=12345&cancelUrl=https%3A%2F%2Fprismade.com&checkUntil=13-07-2024"
                  />
                  <Redirect
                    path="/demou1"
                    to="/upload?greeting=Erika%20Mustermann&driverId=12345&quizNumber=1&uploadUrl=https%3A%2F%2Fedding.de&licenseType=0&skipUpload=true"
                  />
                  <Redirect
                    path="/demou2"
                    to="/upload?greeting=Erika%20Mustermann&driverId=12345&quizNumber=1&uploadUrl=https%3A%2F%2Fedding.de&skipUpload=true"
                  />

                  {/* PhotoUpload */}
                  <Route
                    path="/upload"
                    component={Upload}
                    exact
                  />
                  <Route
                    path="/upload/start"
                    component={UploadStart}
                  />
                  <Route
                    path="/upload/select"
                    component={UploadSelect}
                  />
                  <Route
                    path="/upload/front/place"
                    component={UploadFrontPlace}
                  />
                  <Route
                    path="/upload/front/task"
                    component={UploadFrontTask}
                  />
                  <Route
                    path="/upload/front/confirm"
                    component={UploadFrontConfirm}
                  />
                  <Route
                    path="/upload/back/place"
                    component={UploadBackPlace}
                  />
                  <Route
                    path="/upload/back/confirm"
                    component={UploadBackConfirm}
                  />
                  <Route
                    path="/upload/success"
                    component={UploadSuccess}
                  />

                  {/* INFO catch-all route must be last in the old router version */}
                  <Route component={Notification} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            <CookieBanner />
          </div>
        )}
      />
    );
  }
}

export default withRouter(RouterComponent);
