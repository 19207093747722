import { typedAction } from "../helpers";

type SelectState = {
  currentSelect: number;
};

const initialState: SelectState = {
  currentSelect: 0,
};

export const resetData = () => {
  return typedAction("tutorial/RESET_DATA");
};

export const setCurrentSelect = (currentSelect: number) => {
  return typedAction("tutorial/SET_CURRENT_SELECT", currentSelect);
};

type SelectAction = ReturnType<typeof resetData | typeof setCurrentSelect>;

export function selectReducer(
  state = initialState,
  action: SelectAction
): SelectState {
  switch (action.type) {
    case "tutorial/RESET_DATA":
      return {
        ...initialState,
      };
    case "tutorial/SET_CURRENT_SELECT":
      return {
        ...state,
        currentSelect: action.payload,
      };
    default:
      return state;
  }
}
