import React, { ReactNode } from "react";
import DeviceOrientation, { Orientation } from "../../../helpers/OrientationDetection";
import { Helmet } from "react-helmet";

import rotateDevice from "../../../assets/common/rotate-device.png";

//
type OrientationLockProps = {
  children: ReactNode;
};

type OrientationLockStates = {
  orientation: string;
};

class OrientationLock extends React.Component<OrientationLockProps, OrientationLockStates> {
  constructor(props: OrientationLockProps) {
    super(props);

    this.state = {
      orientation: "portrait",
    };
  }

  shouldComponentUpdate(nextProps: OrientationLockProps, nextState: OrientationLockStates) {
    if (this.state.orientation === nextState.orientation) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>

        <DeviceOrientation
          lockOrientation={"portrait"}
          onLockOrientation={(success: any) => {}}
          onOrientationChange={(orientation: any) => {
            this.setState({ orientation: orientation });
            console.log("device orientation changed to: ", orientation);
          }}
        >
          {/* Will only be in DOM in portrait */}
          <Orientation
            orientation="portrait"
            alwaysRender={false}
          >
            {this.props.children}
          </Orientation>

          {/* Will only be in DOM in landscape */}
          <Orientation
            orientation="landscape"
            alwaysRender={false}
          >
            <div className="flex content-center justify-center w-full h-dvh">
              <img
                src={rotateDevice}
                alt="Please rotate your device."
                className="m-auto max-h-50dvh"
              />
            </div>
          </Orientation>
        </DeviceOrientation>
      </>
    );
  }
}

export default OrientationLock;
