import classNames from "classnames";
import Text from "./Text";

const TitleBlock = ({ p1, p2, className, textClassName }: { p1: string; p2?: string | any; className?: string; textClassName?: string }) => {
  return (
    <div className={className}>
      <span className="hidden xs:block">
        <Text
          type="Headline L"
          className={textClassName}
        >
          {p1}
        </Text>
        <Text
          type="Body"
          className={classNames("mt-1", textClassName)}
        >
          {p2}
        </Text>
      </span>
      <span className="xs:hidden">
        <Text
          type="Headline M small"
          className={textClassName}
        >
          {p1}
        </Text>
        <Text
          type="Caption A"
          className={classNames("mt-1", textClassName)}
        >
          {p2}
        </Text>
      </span>
    </div>
  );
};

export default TitleBlock;
