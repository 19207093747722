import { typedAction } from "../helpers";

export type TouchSensitivityType = "glovemode" | "pointerspeed" | "touchsensitivity";

type AppState = {
  invocationIsValid: boolean;
  allowCookies: boolean;
  startDate: Date;
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
  screenIsBigEnough: boolean;
  displayZoomEnabled: boolean;
  needsTouchSensitivity: boolean;
  touchSensitivityType: TouchSensitivityType | undefined;
  touchSensitivityIsSet: boolean;
  isValidFailureRequest: boolean;
  isRollout?: boolean;
  slideCount?: number;
  currentFormSlide?: number;
  uploadData?: any;
};

const initialState: AppState = {
  invocationIsValid: false,
  allowCookies: false,
  startDate: new Date(),
  deviceIsSupported: true,
  browserIsSupported: true,
  screenIsBigEnough: true,
  displayZoomEnabled: false,
  needsTouchSensitivity: false,
  touchSensitivityIsSet: false,
  touchSensitivityType: undefined,
  // touchSensitivityType: "touchsensitivity",
  isValidFailureRequest: false,
  isRollout: false,
  slideCount: 10,
  currentFormSlide: 0,
  uploadData: [],
};

export const resetData = () => {
  return typedAction("app/RESET_DATA");
};

export const setInvocationIsValid = (invocationIsValid: boolean) => {
  return typedAction("app/SET_INVOKATION_IS_VALID", invocationIsValid);
};

export const setAllowCookies = (allowCookies: boolean) => {
  return typedAction("app/SET_ALLOW_COOKIES", allowCookies);
};

export const setStartDate = (startDate: Date) => {
  return typedAction("app/SET_START_DATE", startDate);
};

export const setDeviceIsSupported = (deviceIsSupported: boolean) => {
  return typedAction("app/SET_DEVICE_IS_SUPPORTED", deviceIsSupported);
};

export const setBrowserIsSupported = (browserIsSupported: boolean) => {
  return typedAction("app/SET_BROWSER_IS_SUPPORTED", browserIsSupported);
};

export const setScreenIsBigEnough = (screenIsBigEnough: boolean) => {
  return typedAction("app/SET_SCREEN_IS_BIG_ENOUGH", screenIsBigEnough);
};

export const setDisplayZoomEnabled = (displayZoomEnabled: boolean) => {
  return typedAction("app/SET_DISPLAYZOOM_ENABLED", displayZoomEnabled);
};

export const setNeedsTouchSensitivity = (needsTouchSensitivity: boolean) => {
  return typedAction("app/SET_NEEDS_TOUCH_SENSITIVITY", needsTouchSensitivity);
};

export const setTouchSensitivityType = (touchSensitivityType: TouchSensitivityType) => {
  return typedAction("app/SET_TOUCH_SENSITIVITY_TYPE", touchSensitivityType);
};

export const setTouchSensitivityIsSet = (touchSensitivityIsSet: boolean) => {
  return typedAction("app/SET_TOUCH_SENSITIVITY_IS_SET", touchSensitivityIsSet);
};

export const setIsValidFailureRequest = (isValidFailureRequest: boolean) => {
  return typedAction("app/SET_IS_VALID_FAILURE_REQUEST", isValidFailureRequest);
};

export const setIsRollout = (isRollout: boolean) => {
  return typedAction("app/SET_IS_ROLLOUT", isRollout);
};
export const setSlideCount = (slideCount: number) => {
  return typedAction("app/SET_SLIDECOUNT", slideCount);
};
export const setCurrentFormSlide = (currentFormSlide: number) => {
  return typedAction("app/SET_CURRENT_FORM_SLIDE", currentFormSlide);
};
export const setUploadData = (uploadData: any) => {
  return typedAction("app/SET_UPLOAD_DATA", uploadData);
};

type AppAction = ReturnType<
  | typeof resetData
  | typeof setInvocationIsValid
  | typeof setAllowCookies
  | typeof setStartDate
  | typeof setDeviceIsSupported
  | typeof setBrowserIsSupported
  | typeof setScreenIsBigEnough
  | typeof setDisplayZoomEnabled
  | typeof setNeedsTouchSensitivity
  | typeof setTouchSensitivityType
  | typeof setTouchSensitivityIsSet
  | typeof setIsValidFailureRequest
  | typeof setIsRollout
  | typeof setSlideCount
  | typeof setCurrentFormSlide
  | typeof setUploadData
>;

export function appReducer(state = initialState, action: AppAction): AppState {
  switch (action.type) {
    case "app/RESET_DATA":
      return {
        ...initialState,
      };
    case "app/SET_INVOKATION_IS_VALID":
      return {
        ...state,
        invocationIsValid: action.payload,
      };
    case "app/SET_ALLOW_COOKIES":
      return {
        ...state,
        allowCookies: action.payload,
      };
    case "app/SET_START_DATE":
      return {
        ...state,
        startDate: action.payload,
      };
    case "app/SET_DEVICE_IS_SUPPORTED":
      return {
        ...state,
        deviceIsSupported: action.payload,
      };
    case "app/SET_BROWSER_IS_SUPPORTED":
      return {
        ...state,
        browserIsSupported: action.payload,
      };
    case "app/SET_SCREEN_IS_BIG_ENOUGH":
      return {
        ...state,
        screenIsBigEnough: action.payload,
      };
    case "app/SET_DISPLAYZOOM_ENABLED":
      return {
        ...state,
        displayZoomEnabled: action.payload,
      };
    case "app/SET_NEEDS_TOUCH_SENSITIVITY":
      return {
        ...state,
        needsTouchSensitivity: action.payload,
      };
    case "app/SET_TOUCH_SENSITIVITY_TYPE":
      return {
        ...state,
        touchSensitivityType: action.payload,
      };
    case "app/SET_TOUCH_SENSITIVITY_IS_SET":
      return {
        ...state,
        touchSensitivityIsSet: action.payload,
      };
    case "app/SET_IS_VALID_FAILURE_REQUEST":
      return {
        ...state,
        isValidFailureRequest: action.payload,
      };
    case "app/SET_IS_ROLLOUT":
      return {
        ...state,
        isRollout: action.payload,
      };
    case "app/SET_SLIDECOUNT":
      return {
        ...state,
        slideCount: action.payload,
      };
    case "app/SET_CURRENT_FORM_SLIDE":
      return {
        ...state,
        currentFormSlide: action.payload,
      };
    case "app/SET_UPLOAD_DATA":
      return {
        ...state,
        uploadData: action.payload,
      };
    default:
      return state;
  }
}
