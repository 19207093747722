import classNames from "classnames";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LicenseSide, LicenseType } from "../../enums";
import { GA } from "../../helpers/GA/ga";
import { getImageData } from "../../helpers/uploadHelper";
import { RootState } from "../../redux";
import TitleBlock from "../common/uikit/TitleBlock";
import ThreePartLayout from "../common/layouts/ThreePartLayout";
import CameraButton from "../common/uikit/CameraButton";
import Text from "../common/uikit/Text";

import gray from "../../assets/upload/license_gray-front@2x.png";
import paper from "../../assets/upload/license_paper-front@2x.png";
import plastic from "../../assets/upload/license_plastic-front@2x.png";

import backGray from "../../assets/upload/license_gray-back@2x.png";
import backPaper from "../../assets/upload/license_paper-back@2x.png";
import backPlastic from "../../assets/upload/license_plastic-back@2x.png";

import index from "../../assets/upload/01-Zeigefinger.svg";
import thumb from "../../assets/upload/02-Daumen.svg";
import small from "../../assets/upload/03-kleinerFinger.svg";

type Props = {
  side: LicenseSide;
  task?: string;
  takePhoto: any;
  withHighlighting?: boolean;
};

enum Finger {
  Thumb = "thumb",
  Index = "index",
  Small = "small",
}

enum Position {
  BottomRight,
  TopRight,
  Right,
  Top,
  Bottom,
  BottomLeft,
  TopLeft,
  Left,
}

function TaskSlide(props: Props) {
  const { t } = useTranslation("upload");
  const licenseType = useSelector((state: RootState) => state.user.licenseType);

  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("");
  const [lines, setLines] = useState("");
  const [finger, setFinger] = useState<any>();
  const [position, setPosition] = useState<any>();

  useEffect(() => {
    let src = "";
    let title = "";
    let lines = "";

    switch (props.task) {
      // right hand
      case "task.1":
      case "task.2":
      case "task.3":
      case "task.4":
      case "task.5":
      // left hand
      case "task.16":
      case "task.17":
      case "task.18":
        setFinger(Finger.Index);
        break;
      // right hand
      case "task.6":
      case "task.7":
      case "task.8":
      case "task.9":
      case "task.10":
      // left hand
      case "task.19":
      case "task.20":
      case "task.21":
        setFinger(Finger.Thumb);
        break;
      // right hand
      case "task.11":
      case "task.12":
      case "task.13":
      case "task.14":
      case "task.15":
      // left hand
      case "task.22":
      case "task.23":
      case "task.24":
        setFinger(Finger.Small);
        break;
    }

    switch (props.task) {
      case "task.1":
      case "task.6":
      case "task.11":
        setPosition(Position.BottomRight);
        break;
      case "task.2":
      case "task.7":
      case "task.12":
        setPosition(Position.TopRight);
        break;
      case "task.3":
      case "task.8":
      case "task.13":
        setPosition(Position.Right);
        break;
      case "task.4":
      case "task.9":
      case "task.14":
        setPosition(Position.Top);
        break;
      case "task.5":
      case "task.10":
      case "task.15":
        setPosition(Position.Bottom);
        break;
      case "task.16":
      case "task.19":
      case "task.22":
        setPosition(Position.BottomLeft);
        break;
      case "task.17":
      case "task.20":
      case "task.23":
        setPosition(Position.TopLeft);
        break;
      case "task.18":
      case "task.21":
      case "task.24":
        setPosition(Position.Left);
        break;
    }

    switch (props.side) {
      case LicenseSide.Front1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = plastic;
            title = t("front.title.plastic");
            lines = props.task ? t(props.task) : "";
            break;
          case LicenseType.Paper:
            src = paper;
            title = t("front.title.paper");
            lines = props.task ? t(props.task) : "";
            break;
          case LicenseType.Gray:
            src = gray;
            title = t("front.title.gray");
            lines = props.task ? t(props.task) : "";
            break;
          default:
            break;
        }
        break;

      case LicenseSide.Back1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = backPlastic;
            title = t("back.title.plastic");
            lines = t("back.turnAndPhotograph");
            break;
          case LicenseType.Paper:
            src = backPaper;
            title = t("back.title.paper");
            lines = t("back.turnAndPhotograph");
            break;
          case LicenseType.Gray:
            src = backGray;
            title = t("back.title.gray");
            lines = t("back.turnAndPhotograph");
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    setSrc(src);
    setTitle(title);
    setLines(lines);
  }, [props]);

  const handleChange = (e: any) => {
    new GA().trackEvent(window, "upload_task_click_takePhoto");
    let image = getImageData(e);
    props.takePhoto(image);
  };

  return (
    <ThreePartLayout
      top={
        <TitleBlock
          p1={title}
          p2={<Trans t={t}>{lines}</Trans>}
        />
      }
      middle={
        <div>
          <div
            className={classNames(
              props.side === LicenseSide.Front1 && "w-2/3",
              props.side === LicenseSide.Back1 && "w-full px-8",
              "relative flex items-center mx-auto mb-8"
            )}
          >
            <img
              src={src}
              alt="license"
              className="object-contain"
            />

            {finger === Finger.Index && position === Position.BottomRight && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 -right-16 rotate-40 -bottom-16"
              />
            )}
            {finger === Finger.Thumb && position === Position.BottomRight && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 -right-20 -bottom-8"
              />
            )}
            {finger === Finger.Small && position === Position.BottomRight && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -right-14 rotate-40 -bottom-20"
              />
            )}

            {finger === Finger.Index && position === Position.TopRight && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 -right-20 -top-2"
              />
            )}
            {finger === Finger.Thumb && position === Position.TopRight && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 -right-20 -top-6"
              />
            )}
            {finger === Finger.Small && position === Position.TopRight && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -right-20 rotate-5 -top-2"
              />
            )}

            {finger === Finger.Index && position === Position.Right && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 -right-20 top-12"
              />
            )}
            {finger === Finger.Thumb && position === Position.Right && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform top-10 w-22 -right-20"
              />
            )}
            {finger === Finger.Small && position === Position.Right && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -right-20 top-16 rotate-10"
              />
            )}

            {finger === Finger.Index && position === Position.Top && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 left-40/100 -top-16 -rotate-70"
              />
            )}
            {finger === Finger.Thumb && position === Position.Top && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform -rotate-45 w-22 -top-16 left-40/100"
              />
            )}
            {finger === Finger.Small && position === Position.Top && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 left-50/100 -top-16 -rotate-70"
              />
            )}

            {finger === Finger.Index && position === Position.Bottom && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 rotate-40 -bottom-16 left-40/100"
              />
            )}
            {finger === Finger.Thumb && position === Position.Bottom && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 rotate-40 -bottom-16 left-4/10"
              />
            )}
            {finger === Finger.Small && position === Position.Bottom && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 rotate-40 -bottom-20 left-40/100"
              />
            )}

            {finger === Finger.Index && position === Position.BottomLeft && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 -left-20 -bottom-12 -scale-x-1"
              />
            )}
            {finger === Finger.Thumb && position === Position.BottomLeft && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 -left-16 -bottom-12 -scale-x-1 -rotate-30"
              />
            )}
            {finger === Finger.Small && position === Position.BottomLeft && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -left-14 -rotate-30 -bottom-20 -scale-x-1"
              />
            )}

            {finger === Finger.Index && position === Position.TopLeft && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform w-22 -left-20 -top-4 -scale-x-1"
              />
            )}
            {finger === Finger.Thumb && position === Position.TopLeft && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 -left-20 -top-3 -scale-x-1 -rotate-30"
              />
            )}
            {finger === Finger.Small && position === Position.TopLeft && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -left-20 -top-3 -scale-x-1"
              />
            )}

            {finger === Finger.Index && position === Position.Left && (
              <img
                src={index}
                alt=""
                className="absolute object-contain transform -top-2 w-22 -left-20 -scale-x-1"
              />
            )}
            {finger === Finger.Thumb && position === Position.Left && (
              <img
                src={thumb}
                alt=""
                className="absolute object-contain transform w-22 -left-20 -top-2 -scale-x-1 -rotate-30"
              />
            )}
            {finger === Finger.Small && position === Position.Left && (
              <img
                src={small}
                alt=""
                className="absolute object-contain transform w-22 -left-20 top-40/100 -scale-x-1 -rotate-10"
              />
            )}
            {/* currently this is implicitly only called for backside 1/2 */}
            {/* HINT: if needed check for side */}
          </div>

          {props.withHighlighting && (
            <div className="w-full px-8 mb-6">
              <div className="w-full p-2 mx-auto bg-white rounded">
                <Text
                  type="Caption A"
                  className="text-easycheck-red"
                >
                  <b>{t("tip")}</b>
                  <p className="inline">{licenseType === LicenseType.Plastic ? t("visibility.labelAndNumber") : t("visibility.label")}</p>
                </Text>
              </div>
            </div>
          )}
        </div>
      }
      bottom={
        <div className="flex items-center justify-end px-8">
          <CameraButton
            title={props.side === LicenseSide.Front1 ? t("button.understood") : t("button.next")}
            onChange={(e: any) => handleChange(e)}
          />
        </div>
      }
    />
  );
}

export default TaskSlide;
