import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import React, { createRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import StartDateCheck from "../components/common/checks/StartDateCheck";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import OrientationLock from "../components/common/layouts/OrientationLock";
import NetworkIndicator from "../components/swipe/NetworkIndicator";
import ProgressBar from "../components/swipe/ProgressBar";
import SwipeField from "../components/swipe/SwipeField";
import { ErrorCode } from "../enums";
import { RootState } from "../redux";
import { setErrorCode } from "../redux/modules/error";
import { clearSwipeResults } from "../redux/modules/swipe";
import SwipeMessage from "../components/swipe/SwipeMessage";
import ProcessIndicator from "../components/swipe/ProcessIndicator";

// types
const mapStateToProps = (state: RootState) => ({
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  clearSwipeResults,
  setErrorCode,
};

type SwipeProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RouteComponentProps;

type SwipeStates = {
  redirect: any;
};

// component
class Swipe extends React.Component<SwipeProps, SwipeStates> {
  private containerRef = createRef<HTMLDivElement>();

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    const container = this.containerRef.current;
    if (container) {
      disableBodyScroll(container);
    }

    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();

    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <StartDateCheck>
        <OrientationLock>
          <div>
            <Helmet>
              <meta
                name="viewport"
                content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Helmet>
            <div
              ref={this.containerRef}
              id="swipeScreen"
            >
              <LockedHeightNoHeader>
                <SwipeField />
                <SwipeMessage />
                <ProgressBar />
                <ProcessIndicator />
                <NetworkIndicator />
              </LockedHeightNoHeader>
            </div>
          </div>
        </OrientationLock>
      </StartDateCheck>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Swipe));
