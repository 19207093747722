import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const ProcessIndicator = () => {
  const { showProcessIndicator } = useSelector((state: RootState) => {
    return {
      showProcessIndicator: state.swipe.showProcessIndicator,
    };
  });

  return showProcessIndicator ? (
    <div className="absolute inset-x-0 top-0 bg-easycheck-shade-light touch-none none">
      <div className="w-full h-1 my-2 overflow-hidden bg-white">
        <div className="w-full h-full bg-easycheck-bluepurple origin-[0%_50%] indeterminateProcess"></div>
      </div>
      <p className="my-2 text-center">Processing...</p>
    </div>
  ) : null;
};

export default ProcessIndicator;
