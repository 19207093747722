import { useTranslation } from "react-i18next";
import Slide from "../common/uikit/Slide";
import TitleBlock from "../common/uikit/TitleBlock";
import TutorialImage from "./TutorialImage";
import TutorialVideo from "./TutorialVideo";

import img_prepare from "../../assets/onboarding/onboarding_prepare.jpg";
import img_placeFingers from "../../assets/onboarding/onboarding_placeFingers.jpg";
import place from "../../assets/onboarding/01_video_place_klein.mp4";
import swipe from "../../assets/onboarding/02_video_swipe_klein.mp4";

const TutorialSlides = () => {
  const { t } = useTranslation("app");

  return (
    <>
      <Slide>
        <TutorialImage
          src={img_prepare}
          alt="prepare device"
        />
        <TitleBlock
          className="w-screen px-8 pb-2 -mx-8 bg-easycheck-light-blue"
          p1={t("slide.prepare.p1")}
          p2={t("slide.prepare.p2")}
        />
      </Slide>

      <Slide>
        <TutorialVideo src={place} />
        <TitleBlock
          className="w-screen px-8 pb-2 -mx-8 bg-easycheck-light-blue"
          p1={t("slide.placeCard.p1")}
        />
      </Slide>

      <Slide>
        <TutorialImage
          src={img_placeFingers}
          alt="place fingers"
        />
        <TitleBlock
          className="w-screen px-8 pb-2 -mx-8 bg-easycheck-light-blue"
          p1={t("slide.placeFingers.p1")}
          p2={t("slide.placeFingers.p2")}
        />
      </Slide>

      <Slide>
        <TutorialVideo src={swipe} />
        <TitleBlock
          className="w-screen px-8 pb-2 -mx-8 bg-easycheck-light-blue"
          p1={t("slide.swipe.p1")}
        />
      </Slide>
    </>
  );
};

export default TutorialSlides;
