import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux";
import Code from "../../common/Code";
import Text from "../../common/uikit/Text";

const OptionQRCode = () => {
  const { t } = useTranslation();

  const { originalURL } = useSelector((state: RootState) => {
    return {
      originalURL: state.user.originalURL,
    };
  });

  return (
    <Link to="/code">
      <div className="flex flex-row items-center px-2 py-3 mx-auto space-x-4 text-left rounded md:max-w-sm">
        <div className="flex-grow-0 flex-shrink-0 w-28 h-28">
          <Code url={originalURL} />
        </div>
        <Text type="Body">{t("failure:qrCode1")}</Text>
      </div>
    </Link>
  );
};

export default OptionQRCode;
