import Solution from "../components/troubleshoot/Solution";
import useInvocationCheck from "../hooks/useInvocationCheck";

export const TroubleshootZoom = () => {
  useInvocationCheck();

  return <Solution type="zoom" />;
};

export default TroubleshootZoom;
