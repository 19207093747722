import QRCode from "qrcode.react";
import React from "react";

interface Props {
  url: string;
  codeColor?: string;
  bgColor?: string;
}

const Code: React.FC<Props> = ({ url, codeColor, bgColor }: Props) => {
  return (
    <QRCode
      value={url}
      // TODO read easycheck-bluepurple from config
      style={{ height: "100%", width: "100%" }}
      fgColor={codeColor || "#070084"}
      bgColor={bgColor || "#ffffff"}
    />
  );
};

export default Code;
