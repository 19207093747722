import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TitleBlock from "../components/common/uikit/TitleBlock";
import Text from "../components/common/uikit/Text";
import ThreePartLayout from "../components/common/layouts/ThreePartLayout";
import ButtonTab from "../components/common/uikit/ButtonTab";
import useInvocationCheck from "../hooks/useInvocationCheck";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import { useEffect, useState } from "react";
import { setLicenseType } from "../redux/modules/user";
import { LicenseType } from "../enums";

import gray from "../assets/select/license_gray@2x.png";
import paper from "../assets/select/license_paper@2x.png";
import plastic from "../assets/select/license_plastic@2x.png";

function UploadSelect() {
  const { t } = useTranslation(["app"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [emblaRef, emblaApi] = useEmblaCarousel();

  useInvocationCheck();

  const { type } = useSelector((state: RootState) => {
    return {
      type: state.user.licenseType,
    };
  });

  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    switch (type) {
      case 0:
        setSelectedType("plastic");
        emblaApi?.scrollTo(0);
        break;
      case 1:
        setSelectedType("paper");
        emblaApi?.scrollTo(1);
        break;
      case 2:
        setSelectedType("gray");
        emblaApi?.scrollTo(2);
        break;
      default:
        setSelectedType("plastic");
        emblaApi?.scrollTo(0);
        break;
    }
  }, [emblaApi, type]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("select", () => {
      switch (emblaApi.selectedScrollSnap()) {
        case 0:
          dispatch(setLicenseType(LicenseType.Plastic));
          break;
        case 1:
          dispatch(setLicenseType(LicenseType.Paper));
          break;
        case 2:
          dispatch(setLicenseType(LicenseType.Gray));
          break;
        default:
      }
    });
  }, [emblaApi]);

  const handleOnClick = () => {
    history.push("/upload/front/place");
  };

  return (
    <ThreePartLayout
      top={<TitleBlock p1={t("upload:selectLicense")} />}
      middle={
        <div
          className="flex flex-col mt-4 overflow-hidden grow"
          ref={emblaRef}
        >
          <div className="flex grow">
            <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 ml-[2rem] flex">
              <div className="p-4 my-auto h-50dvh">
                <div
                  className={classNames(
                    "p-4 rounded-lg transition-colors ease-in-out duration-200",
                    selectedType === "plastic" && "bg-easycheck-bluepurple",
                    selectedType !== "plastic" && "bg-white"
                  )}
                >
                  <img
                    src={plastic}
                    alt={t("select.plastic")}
                  />
                </div>
                <div className="mt-6">
                  <Text type="Body">{t("select.plastic")}</Text>
                  <Text type="Caption A">{t("select.plastic.issued")}</Text>
                </div>
              </div>
            </div>

            <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem] flex">
              <div className="p-4 my-auto h-50dvh">
                <div className={classNames("p-4 rounded-lg", selectedType === "paper" && "bg-easycheck-bluepurple", selectedType !== "paper" && "bg-white")}>
                  <img
                    src={paper}
                    alt={t("select.paper")}
                  />
                </div>
                <div className="mt-6">
                  <Text type="Body">{t("select.paper")}</Text>
                  <Text type="Caption A">{t("select.paper.issued")}</Text>
                </div>
              </div>
            </div>

            <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem] mr-[2rem] flex">
              <div className="p-4 my-auto h-50dvh">
                <div className={classNames("p-4 rounded-lg", selectedType === "gray" && "bg-easycheck-bluepurple", selectedType !== "gray" && "bg-white")}>
                  <img
                    src={gray}
                    alt={t("select.gray")}
                  />
                </div>
                <div className="mt-6">
                  <Text type="Body">{t("select.gray")}</Text>
                  <Text type="Caption A">{t("select.gray.issued")}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      bottom={
        <ButtonTab
          backButton_onClick={undefined}
          backButton_show={false}
          nextButton_onClick={handleOnClick}
          nextButton_children={t("upload:button.confirm")}
        />
      }
    />
  );
}

export default UploadSelect;
