export const registerMovementDetection = () => {
  if (typeof DeviceMotionEvent !== "undefined" && typeof (DeviceMotionEvent as any).requestPermission === "function") {
    (DeviceMotionEvent as any)
      .requestPermission()
      .then((response: string) => {
        if (response === "granted") {
          //window.addEventListener( "devicemotion", this.handleDeviceMotion)
        }
      })
      .catch(console.error);
  } else {
    console.log("DeviceMotionEvent is not defined");
  }
  if (typeof DeviceOrientationEvent !== "undefined" && typeof (DeviceOrientationEvent as any).requestPermission === "function") {
    (DeviceOrientationEvent as any)
      .requestPermission()
      .then((response: string) => {
        if (response === "granted") {
          //window.addEventListener('deviceorientation', () => {});
        }
      })
      .catch(console.error);
  } else {
    // handle regular non iOS 13+ devices
  }
};
