import { ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorCode } from "../../../enums";
import useInterval from "../../../hooks/useInterval";
import { RootState } from "../../../redux";
import { setErrorCode } from "../../../redux/modules/error";

const StartDateCheck = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const startDate = useSelector((state: RootState) => state.app.startDate);

  const setErrorCodeAndRedirect = useCallback((errorCode: ErrorCode) => {
    dispatch(setErrorCode(errorCode));
    history.push("/error");
  }, []);

  const checkStartDate = useCallback(() => {
    const now = new Date();
    let timeDiffInMS: number = now.getTime() - startDate.getTime();
    let hoursDiff: number = timeDiffInMS / (1000 * 60 * 60);

    // SessionExpired if link was opened more than 1 hour ago
    if (hoursDiff > 1) {
      setErrorCodeAndRedirect(ErrorCode.SessionExpired);
    }
  }, []);

  useInterval(checkStartDate, 60000);

  // TODO doesn't need to contain the children, since it is redirecting inside the component
  return <>{children}</>;
};

export default StartDateCheck;
