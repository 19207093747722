import { PrismaSDK, UsabilityResponse } from "@prismadelabs/prismaid";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import SDKSingleton from "../SDK";
import { LicenseType } from "../enums";
import { getScaleFactor } from "../helpers/scale";
import { screenIsTooWide } from "../helpers/screenSizeCheck";
import { isValidUrl } from "../helpers/urlCheck";
import { RootState } from "../redux";
import {
  setBrowserIsSupported,
  setDeviceIsSupported,
  setDisplayZoomEnabled,
  setInvocationIsValid,
  setIsValidFailureRequest,
  setNeedsTouchSensitivity,
  setScreenIsBigEnough,
  setStartDate,
  setTouchSensitivityType,
} from "../redux/modules/app";
import { setScaleFactor } from "../redux/modules/swipe";
import { setEventUrl, setUserData } from "../redux/modules/user";

const mapStateToProps = (state: RootState) => ({
  isValidFailureRequest: state.app.isValidFailureRequest,
});

const mapDispatchToProps = {
  setUserData,
  setInvocationIsValid,
  setStartDate,
  setDeviceIsSupported,
  setBrowserIsSupported,
  setScreenIsBigEnough,
  setDisplayZoomEnabled,
  setNeedsTouchSensitivity,
  setTouchSensitivityType,
  setScaleFactor,
  setEventUrl,
  setIsValidFailureRequest,
};

type OpenProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation;

type OpenStates = {
  redirect: string | null;
  scaleIsReady: boolean;
  supportedIsReady: boolean;
};

// component
class Open extends React.Component<OpenProps, OpenStates> {
  driverId?: string;
  cancelUrl: string = "";

  constructor(props: OpenProps) {
    super(props);

    this.state = {
      redirect: null,
      scaleIsReady: false,
      supportedIsReady: false,
    };

    const parsed = queryString.parse(window.location.search);
    var data = {} as any;

    // ?greeting=Ron&mode=a039b&callbackUrl=nocallback&licenseType=1&originalURL=https%3A%2F%2Fedding.de&canRetry=false&cancelUrl=https%3A%2F%2Fprismade.com&driverId=abcde
    if (parsed.greeting) {
      data = { ...data, greeting: parsed.greeting };
    }
    if (parsed.mode) {
      data = { ...data, mode: parsed.mode };
    }
    if (parsed.driverId) {
      data = { ...data, driverId: parsed.driverId };
      this.driverId = parsed.driverId.toString();
    }
    if (parsed.callbackUrl) {
      const callback = parsed.callbackUrl;

      if (typeof callback === "string" && isValidUrl(callback)) {
        data = { ...data, callbackUrl: callback };
      }
    }
    if (parsed.licenseType === LicenseType.Plastic.toString()) {
      data = {
        ...data,
        licenseType: LicenseType.Plastic,
        licenseIsKnown: true,
      };
    }
    if (parsed.licenseType === LicenseType.Paper.toString()) {
      data = { ...data, licenseType: LicenseType.Paper, licenseIsKnown: true };
    }
    if (parsed.licenseType === LicenseType.Gray.toString()) {
      data = { ...data, licenseType: LicenseType.Gray, licenseIsKnown: true };
    }
    if (parsed.originalURL) {
      const original = parsed.originalURL;

      if (typeof original === "string" && isValidUrl(original)) {
        data = { ...data, originalURL: original };
      }
    }
    if (parsed.cancelUrl) {
      const cancel = parsed.cancelUrl;

      if (typeof cancel === "string" && isValidUrl(cancel)) {
        data = { ...data, cancelUrl: cancel };
        this.cancelUrl = cancel;
      }
    }
    if (parsed.eventUrl) {
      const event = parsed.eventUrl;

      if (typeof event === "string" && isValidUrl(event)) {
        data = { ...data, eventUrl: event };
      }
    }
    if (parsed.canRetry === "true") {
      data = { ...data, canRetry: true };
    } else {
      data = { ...data, canRetry: false };
    }
    // url with token to backend that checks for the params needed for photoUpload(quizNumber/...)
    if (parsed.uploadUrl) {
      const upload = parsed.uploadUrl;
      data = { ...data, uploadUrl: upload.toString() };
    }
    if (parsed.checkUntil) {
      const checkUntil = moment(parsed.checkUntil.toString(), "DD-MM-YYYY");
      const now = moment();
      const daysLeft = checkUntil.diff(now, "days");

      data = { ...data, daysLeft: daysLeft };
    }
    if (
      data.hasOwnProperty("greeting") &&
      data.hasOwnProperty("mode") &&
      data.hasOwnProperty("callbackUrl") &&
      data.hasOwnProperty("originalURL") &&
      data.hasOwnProperty("canRetry") &&
      data.hasOwnProperty("driverId")
    ) {
      // require greeting, mode, callbackUrl, originalURL, canRetry, driverId
      // licenseType, cancelUrl, checkUntil, uploadUrl are not required
      props.setUserData(data);
      props.setInvocationIsValid(true);
      props.setStartDate(new Date());
    }
    // invalid params, but valid failureScreenRequest
    else if (data.hasOwnProperty("eventUrl")) {
      props.setEventUrl(data.eventUrl);

      props.setInvocationIsValid(true);
      props.setIsValidFailureRequest(true);
    }

    this.getSDKInitializationData();
  }

  getSDKInitializationData() {
    const sdk: PrismaSDK = SDKSingleton.getInstance({ driverId: this.driverId }).sdk;

    sdk.setInteractiveHelperFailedCallbackURL(this.cancelUrl);

    sdk.getInitialisationSubject().subscribe((response) => {
      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          this.props.setScaleFactor(scale);
        }
      } else {
        this.props.setDeviceIsSupported(false);
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("touchsensitivity");
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("glovemode");
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("pointerspeed");
      }

      if (response.isDisplayZoomEnabled) {
        this.props.setDisplayZoomEnabled(true);
      }

      setTimeout(() => {
        this.setState({
          scaleIsReady: true,
        });
      }, 10);
    });

    // TODO move to decoder
    if (screenIsTooWide()) {
      this.props.setDeviceIsSupported(false);
    }

    sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      if (response.event === "device_not_supported") {
        this.props.setDeviceIsSupported(false);
      }

      if (response.event === "browser_not_supported") {
        this.props.setBrowserIsSupported(false);
      }

      if (response.event === "display_too_small_displacement") {
        this.props.setScreenIsBigEnough(false);
      }

      this.setState({
        supportedIsReady: true,
      });
    });
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    // note: UsabilitySubject may also come back multiple times or not at all.
    // Redirect to next screen when scaleIsReady, and add some wait-time to allow for possible usabilitySubjectResponse.
    if (this.state.scaleIsReady && this.props.isValidFailureRequest) {
      return <Redirect to="/failure" />;
    }
    if (this.state.scaleIsReady && !this.props.isValidFailureRequest) {
      return <Redirect to="/" />;
    }

    return null;
  }
}

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(Open);
