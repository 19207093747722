import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";

import { ReactComponent as ArrowLeft } from "../../../assets/icons/icon-arrow-left.svg";

type BackButtonProps = {
  size?: "default" | "small";
  asChild?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const baseClasses =
  "bg-white rounded-full inline-flex items-center justify-center active:bg-easycheck-light-blue text-easycheck-bluepurple active:text-easycheck-blue";

const sizeClasses: Record<NonNullable<BackButtonProps["size"]>, string> = {
  default: "size-[3.25rem]",
  small: "size-[2.625rem]",
};
const iconSizeClasses: Record<NonNullable<BackButtonProps["size"]>, string> = {
  default: "size-[2.5rem]",
  small: "size-[2rem]",
};

// TODO sound onClick
const BackButton = ({ size = "default", asChild, ...restProps }: BackButtonProps) => {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      {...restProps}
      className={classNames(baseClasses, sizeClasses[size])}
    >
      <ArrowLeft className={classNames(iconSizeClasses[size])} />
    </Comp>
  );
};

export default BackButton;
