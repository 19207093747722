import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";

export const Troubleshoot = () => {
  const history = useHistory();

  const { q_oldPhone, q_zoomActivated } = useSelector((state: RootState) => {
    return {
      q_oldPhone: state.onboarding.q_oldPhone,
      q_zoomActivated: state.onboarding.q_zoomActivated,
      qrcode: state.user.originalURL,
    };
  });

  useInvocationCheck();

  useEffect(() => {
    if (q_oldPhone === "yes" && q_zoomActivated === "yes") {
      history.replace("/troubleshoot/phoneAndZoom");
    }
    if (q_oldPhone === "yes" && q_zoomActivated === "no") {
      history.replace("/troubleshoot/phone");
    }
    if (q_oldPhone === "no" && q_zoomActivated === "yes") {
      history.replace("/troubleshoot/zoom");
    }
  }, [q_oldPhone, q_zoomActivated]);

  return null;
};

export default Troubleshoot;
