import Solution from "../components/troubleshoot/Solution";
import useInvocationCheck from "../hooks/useInvocationCheck";

export const TroubleshootPhoneAndZoom = () => {
  useInvocationCheck();

  return <Solution type="phoneAndZoom" />;
};

export default TroubleshootPhoneAndZoom;
