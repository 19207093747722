import { useHistory } from "react-router-dom";
import PlacementSlide from "../components/upload/PlacementSlide";
import { LicenseSide } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";

function UploadFrontPlace() {
  const history = useHistory();

  useInvocationCheck();

  const handleOnClick = () => {
    history.push("/upload/front/task");
  };
  return (
    <PlacementSlide
      side={LicenseSide.Front1}
      nextSlide={handleOnClick}
    />
  );
}

export default UploadFrontPlace;
