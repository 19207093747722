import React, { useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import Button from "../common/uikit/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setCookieData } from "../../redux/modules/cookie";
import { GA } from "../../helpers/GA/ga";

function CookieBanner() {
  const { t } = useTranslation("cookies");
  const dispatch = useDispatch();

  const { showCookieBanner } = useSelector((state: RootState) => {
    return {
      showCookieBanner: state.cookie.showCookieBanner,
    };
  });

  const [cookie, setCookie] = useCookies(["eDLC_cookies_allowed"]);

  const onDecline = () => {
    dispatch(setCookieData({ showCookieBanner: false, isCookieAllowed: false }));
  };

  const onAccept = () => {
    dispatch(setCookieData({ showCookieBanner: false, isCookieAllowed: true }));

    setCookie("eDLC_cookies_allowed", "true", {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    new GA().enableGAWindowProperty(window);
  };

  useEffect(() => {
    // skip banner if cookie is already set
    const cookieValue = cookie.eDLC_cookies_allowed;

    if (cookieValue && cookieValue === "true") {
      dispatch(setCookieData({ showCookieBanner: false, isCookieAllowed: true }));
      return;
    }
  }, []);

  return (
    <Dialog
      open={showCookieBanner}
      onClose={() => {}}
      as="div"
      className=""
    >
      <div className="fixed inset-0 bg-black bg-opacity-50" />
      <DialogPanel>
        <div className="absolute top-0 left-0 flex items-center justify-center w-screen p-8 text-center h-dvh">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <DialogTitle>
              <p className="text-lg font-bold">{t("title")}</p>
            </DialogTitle>

            <p className="mt-4">{t("body")}</p>

            <div className="inline-flex items-center justify-between gap-4 mt-8">
              <Button
                onClick={onDecline}
                weight="secondary"
              >
                {t("decline")}
              </Button>
              <Button onClick={onAccept}>{t("accept")}</Button>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
}

export default CookieBanner;
