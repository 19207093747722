import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UniversalFailureScreen from "../components/failure/UniversalFailureScreen";
import { ErrorCode } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";
import { setInvocationIsValid } from "../redux/modules/app";
import { setErrorCode } from "../redux/modules/error";

const Failure = () => {
  // TODO move uploadUrl to component
  const { canRetry, uploadUrl } = useSelector((state: RootState) => {
    return {
      canRetry: state.user.canRetry,
      uploadUrl: state.user.uploadUrl,
    };
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useInvocationCheck();

  useEffect(() => {
    return () => {
      dispatch(setInvocationIsValid(false));
      dispatch(setErrorCode(ErrorCode.InvalidInvocation));
      history.replace("/app");
    };
  }, []);

  if (canRetry) {
    return (
      <UniversalFailureScreen
        suggestion1
        qrCode
        uploadUrl={uploadUrl}
        manualControl
      />
    );
  } else {
    return (
      // finalFailure
      <UniversalFailureScreen
        suggestion1
        uploadUrl={uploadUrl}
        manualControl
        calendly
      />
    );
  }
};

export default Failure;
