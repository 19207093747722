import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { ErrorCode } from "../enums";
import { RootState } from "../redux";
import { setErrorCode } from "../redux/modules/error";

import logo from "../assets/logos/startScreenLogo.svg";

// types
const mapStateToProps = (state: RootState) => ({
  invocationIsValid: state.app.invocationIsValid,
  deviceIsSupported: state.app.deviceIsSupported,
  browserIsSupported: state.app.browserIsSupported,
  screenIsBigEnough: state.app.screenIsBigEnough,
  displayZoomEnabled: state.app.displayZoomEnabled,

  licenseType: state.user.licenseType,
});

const mapDispatchToProps = {
  setErrorCode,
};

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RouteComponentProps;

type AppStates = {};

// component
class App extends React.Component<AppProps, AppStates> {
  constructor(props: AppProps) {
    super(props);

    document.addEventListener("touchstart", tapHandler);

    var tappedTwice = false;

    function tapHandler(event: { preventDefault: () => void }) {
      if (!tappedTwice) {
        tappedTwice = true;
        setTimeout(function () {
          tappedTwice = false;
        }, 300);
        return false;
      }
      event.preventDefault();
    }
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    if (!this.props.browserIsSupported) {
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
      return;
    }

    if (!this.props.deviceIsSupported) {
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
      return;
    }

    setTimeout(() => {
      this.props.history.push("/welcome");
    }, 1000);
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    setTimeout(() => {
      this.props.history.push("/error");
    }, 1000);
  };

  render() {
    return (
      <div className="flex items-center justify-center h-dvh">
        <img
          src={logo}
          className="w-3/5 fade-out-image"
          alt="easycheck by edding"
        />
      </div>
    );
  }
}

export default withRouter(compose<any>(connect(mapStateToProps, mapDispatchToProps))(App));
