import { HelpScreenButtonType } from "@prismadelabs/prismaid/dist/model/HelpScreen";
import Cookies from "universal-cookie";

const debug_mode = process.env.REACT_APP_GA_DEBUG === "true";

export type GAEventType =
  // error
  | "error_show_wrong_device"
  | "error_show_wrong_browser"
  | "error_show_zoom_enabled"
  | "error_show_screen_too_small"
  | "error_show_session_expired"
  | "error_show_invocation_invalid"
  | "error_show_link_invalid"
  | "error_show_link_requested"
  | "error_show_upload_is_pending"
  // select
  | "select_swipe"
  // troubleshoot
  | "troubleshoot_click_override_phone"
  | "troubleshoot_click_override_zoom"
  | "troubleshoot_click_override_phone_and_zoom"
  // swipe
  | "network_slow"
  | "network_offline"
  | "swipescreen_incoming_response_success"
  | "swipescreen_incoming_response_failure"
  | "swipescreen_error"
  | "swipescreen_failure_final"
  | "swipescreen_success_again"
  | "swipescreen_success_done"
  // failure
  | "failure_show_qr"
  | "failure_show_photo"
  | "failure_show_support"
  | "failure_click_photo"
  | "failure_click_support"
  // upload
  | "upload_isPending"
  | "upload_missingParameters"
  | "upload_unsupportedDevice"
  | "upload_start_rollout"
  | "upload_start_upload"
  | "upload_task_click_takePhoto"
  | "upload_confirm_click_retry"
  | "upload_placement_click_confirm"
  | "upload_confirm_uploadError"
  | "upload_confirm_click_confirm"
  | "upload_confirm_uploadSuccess";

export class GA {
  trackInteractiveHelpScreen(clientWindow: any, helpScreen: string) {
    // this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", "interactiveHelp_" + helpScreen, { debug_mode });
    }
  }

  trackInteractiveHelpClick(clientWindow: any, helpScreen: string, buttonType: HelpScreenButtonType) {
    let type;
    switch (buttonType) {
      case HelpScreenButtonType.ok:
        type = "ok";
        break;
      case HelpScreenButtonType.yes:
        type = "yes";
        break;
      case HelpScreenButtonType.no:
        type = "no";
        break;
      default:
        type = "error";
        break;
    }

    // this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", "interactiveHelpClick_" + helpScreen + "_" + type, { debug_mode });
    }
  }

  trackEvent(clientWindow: any, event: GAEventType) {
    // this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", event, { debug_mode });
    }
  }

  enableGAWindowProperty(clientWindow: any) {
    if (this.gaIsAllowed()) {
      const property: string = "ga-disable-" + process.env.REACT_APP_GA_ID;

      if (clientWindow[property] === false) {
        return;
      }

      clientWindow[property] = false;
      clientWindow.gtag("js", new Date());
      clientWindow.gtag("config", process.env.REACT_APP_GA_ID, { debug_mode });
    }
  }

  gaIsAllowed() {
    // TODO FIXME in production
    // return true;
    const cookies = new Cookies();
    const gaIsAllowed: string = cookies.get("eDLC_cookies_allowed");

    if (gaIsAllowed && gaIsAllowed.toLowerCase() === "true") {
      return true;
    } else {
      return false;
    }
  }
}
