import { typedAction } from "../helpers";

type CookieState = {
  showCookieBanner: boolean;
  isCookieAllowed: boolean;
};

const initialState: CookieState = {
  showCookieBanner: true,
  isCookieAllowed: false,
};

export const setShowCookieBanner = (showCookieBanner: boolean) => {
  return typedAction("cookie/SET_SHOW_COOKIEBANNER", showCookieBanner);
};
export const setIsCookieAllowed = (isCookieAllowed: boolean) => {
  return typedAction("cookie/SET_IS_COOKIE_ALLOWED", isCookieAllowed);
};
export const setCookieData = (cookieData: { showCookieBanner: boolean; isCookieAllowed: boolean }) => {
  return typedAction("cookie/SET_COOKIE_DATA", cookieData);
};

type CookieAction = ReturnType<typeof setShowCookieBanner | typeof setIsCookieAllowed | typeof setCookieData>;

export function cookieReducer(state = initialState, action: CookieAction): CookieState {
  switch (action.type) {
    case "cookie/SET_SHOW_COOKIEBANNER":
      return {
        ...state,
        showCookieBanner: action.payload,
      };
    case "cookie/SET_IS_COOKIE_ALLOWED":
      return {
        ...state,
        showCookieBanner: action.payload,
      };
    case "cookie/SET_COOKIE_DATA":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
