import { PrismaSDK } from "@prismadelabs/prismaid";
import i18n from "./i18n";

class SDKSingleton {
  private static instance: SDKSingleton;
  sdk: PrismaSDK;

  constructor(props?: {}) {
    if (SDKSingleton.instance) {
      throw new Error("Error - use SDKSingleton.getInstance()");
    }
    this.sdk = new PrismaSDK(process.env.REACT_APP_SDK_API_KEY!, process.env.REACT_APP_SDK_API_URL!, props);
    this.sdk.setTwoFingerHoldingMode(true);
    i18n.changeLanguage(this.sdk.getFinalSDKLanguage());
  }

  static getInstance(customData?: {}): SDKSingleton {
    SDKSingleton.instance = SDKSingleton.instance || new SDKSingleton(customData);
    return SDKSingleton.instance;
  }
}

export default SDKSingleton;
