import moment from "moment";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SDKSingleton from "../SDK";
import ButtonTab from "../components/common/uikit/ButtonTab";
import { RadioGroup, RadioGroupItem } from "../components/common/uikit/RadioGroup";
import Text from "../components/common/uikit/Text";
import TitleBlock from "../components/common/uikit/TitleBlock";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";
import { set_q_oldPhone, set_q_zoomActivated } from "../redux/modules/onboarding";
import { setSwipeResults } from "../redux/modules/swipe";

export const Checklist = () => {
  const history = useHistory();
  const { t } = useTranslation("app");
  const dispatch = useDispatch();

  const { q_oldPhone, q_zoomActivated } = useSelector((state: RootState) => {
    return {
      q_oldPhone: state.onboarding.q_oldPhone,
      q_zoomActivated: state.onboarding.q_zoomActivated,
    };
  });

  useInvocationCheck();

  const prepareFirstSwipeMessages = useCallback(() => {
    dispatch(
      setSwipeResults([
        {
          type: "info",
          text: "swipe:place.body",
        },
      ])
    );
  }, [dispatch]);

  const prepareSwipeScreen = useCallback(() => {
    let sdk = SDKSingleton.getInstance().sdk;
    sdk.getInteractiveHelper().reset();

    prepareFirstSwipeMessages();
  }, [prepareFirstSwipeMessages]);

  const showNextButton = useCallback(() => {
    if (q_oldPhone && q_zoomActivated) return true;
    return false;
  }, [q_oldPhone, q_zoomActivated]);

  const showTroubleshooting = useCallback(() => {
    if (q_oldPhone === "yes" || q_zoomActivated === "yes") return true;
    return false;
  }, [q_oldPhone, q_zoomActivated]);

  const onPrevButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const onNextButtonClick = useCallback(() => {
    prepareSwipeScreen();
    if (showTroubleshooting()) {
      history.push("/troubleshoot");
    } else {
      history.push("/ready");
    }
  }, [showTroubleshooting, history, prepareSwipeScreen]);

  const year = moment().startOf("year").subtract(5, "years").format("YYYY");

  return (
    <div className="flex flex-col text-center min-h-dvh">
      <div className="px-8 topSpacing">
        {/* <div className="px-8 py-8"> */}
        <TitleBlock p1={t("checklist.p1")} />
      </div>
      <div className="flex flex-col justify-around px-8 mb-20 grow">
        <div>
          <div>
            <span className="hidden xs:block">
              <Text type="Headline M">{t("checklist.q_oldPhone", { year: year })}</Text>
            </span>
            <span className="xs:hidden">
              <Text type="Headline M small">{t("checklist.q_oldPhone", { year: year })}</Text>
            </span>
            <RadioGroup
              className="mt-4"
              value={q_oldPhone}
              onValueChange={(value) => dispatch(set_q_oldPhone(value))}
            >
              <RadioGroupItem
                value="yes"
                title={t("button.yes")}
              />
              <RadioGroupItem
                value="no"
                title={t("button.no")}
              />
            </RadioGroup>
          </div>

          <div className="mt-14">
            <span className="hidden xs:block">
              <Text type="Headline M">{t("checklist.q_zoomActivated")}</Text>
            </span>
            <span className="xs:hidden">
              <Text type="Headline M small">{t("checklist.q_zoomActivated")}</Text>
            </span>
          </div>
          <RadioGroup
            className="mt-4"
            value={q_zoomActivated}
            onValueChange={(value) => dispatch(set_q_zoomActivated(value))}
          >
            <RadioGroupItem
              value="yes"
              title={t("button.yes")}
            />
            <RadioGroupItem
              value="no"
              title={t("button.no")}
            />
          </RadioGroup>
        </div>
      </div>

      <ButtonTab
        backButton_onClick={onPrevButtonClick}
        nextButton_onClick={onNextButtonClick}
        nextButton_children={t("button.next")}
        nextButton_show={showNextButton()}
      />
    </div>
  );
};

export default Checklist;
