import { useTranslation } from "react-i18next";
import { ReactComponent as Upload } from "../../../assets/icons/icon-photoupload.svg";
import Text from "../../common/uikit/Text";
import { GA } from "../../../helpers/GA/ga";

type Props = {
  uploadUrl: string;
};

const OptionPhotoUpload = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="rounded bg-edding-blue bg-opacity-15">
      <div className="px-8 py-6 mx-auto md:max-w-sm">
        <a
          href={props.uploadUrl}
          onClick={() => {
            new GA().trackEvent(window, "failure_click_photo");
          }}
          className="flex flex-row items-center space-x-6 text-left"
        >
          <Upload className="flex-grow-0 w-9 h-9 text-easycheck-bluepurple" />

          <div>
            <Text type="Headline M">{t("failure:photoUpload1")}</Text>
            <Text
              type="Caption A"
              className="hidden xs:block"
            >
              {t("failure:photoUpload2")}
            </Text>
          </div>
        </a>
      </div>
    </div>
  );
};

export default OptionPhotoUpload;
