import { ErrorCode } from "../../enums";
import { typedAction } from "../helpers";

type ErrorState = {
  errorCode: ErrorCode | null;
};

const initialState: ErrorState = {
  errorCode: null,
};

export const resetData = () => {
  return typedAction("error/RESET_DATA");
};

export const setErrorCode = (errorCode: ErrorCode) => {
  return typedAction("error/SET_ERRORCODE", errorCode);
};

type ErrorAction = ReturnType<typeof resetData | typeof setErrorCode>;

export function errorReducer(
  state = initialState,
  action: ErrorAction
): ErrorState {
  switch (action.type) {
    case "error/RESET_DATA":
      return {
        ...initialState,
      };
    case "error/SET_ERRORCODE":
      return {
        ...state,
        errorCode: action.payload,
      };
    default:
      return state;
  }
}
