import { Upload } from "react-feather";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ThreePartLayout from "../components/common/layouts/ThreePartLayout";
import ButtonTab from "../components/common/uikit/ButtonTab";
import Text from "../components/common/uikit/Text";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { useSelector } from "react-redux";
import { RootState } from "../redux";

function UploadStart() {
  useInvocationCheck();

  const { t } = useTranslation("upload");
  const history = useHistory();

  const { licenseIsKnown } = useSelector((state: RootState) => {
    return {
      licenseIsKnown: state.user.licenseIsKnown,
    };
  });

  const handleOnClick = () => {
    if (licenseIsKnown) {
      history.push("/upload/front/place");
    } else {
      history.push("/upload/select");
    }
  };

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
      </Helmet>

      <ThreePartLayout
        middle={
          <div className="px-8">
            <Upload className="w-10 h-10 p-1 m-1 mx-auto text-easycheck-bluepurple" />
            <div className="mt-4">
              <span className="hidden xs:block">
                <Text type="Headline L">{t("upload.title")}</Text>
                <Text
                  type="Headline S"
                  className="mt-4 whitespace-pre-wrap"
                >
                  {t("upload.body")}
                </Text>
              </span>
              <span className="xs:hidden">
                <Text type="Headline M small">{t("upload.title")}</Text>

                <Text
                  type="Headline S small"
                  className="mt-4 whitespace-pre-wrap"
                >
                  {t("upload.body")}
                </Text>
              </span>
            </div>
          </div>
        }
        bottom={
          <ButtonTab
            backButton_onClick={undefined}
            backButton_show={false}
            nextButton_onClick={handleOnClick}
            nextButton_children={t("button.ok_start")}
          />
        }
      />
    </div>
  );
}

export default UploadStart;
