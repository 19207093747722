import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TaskSlide from "../components/upload/TaskSlide";
import { LicenseSide } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";
import { setUploadData } from "../redux/modules/app";

function UploadBackPlace() {
  const dispatch = useDispatch();
  const history = useHistory();

  useInvocationCheck();

  const data = useSelector((state: RootState) => state.app.uploadData);

  const takePhoto = (index: number, image: any, retry: boolean = false) => {
    let images = [...data];
    images[index] = image;
    dispatch(setUploadData(images));

    if (!retry) {
      history.push("/upload/back/confirm");
    }
  };

  return (
    <TaskSlide
      side={LicenseSide.Back1}
      takePhoto={(image: any) => {
        takePhoto(2, image);
      }}
      withHighlighting
    />
  );
}

export default UploadBackPlace;
