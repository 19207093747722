import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Slide from "../common/uikit/Slide";
import TitleBlock from "../common/uikit/TitleBlock";
import Step from "../common/uikit/Step";
import Text from "../common/uikit/Text";
import { RootState } from "../../redux";

const TouchSettingsSlides = () => {
  const { t } = useTranslation("app");

  const { touchSetting } = useSelector((state: RootState) => {
    return {
      touchSetting: state.app.touchSensitivityType,
    };
  });

  if (!touchSetting) return null;

  return (
    <Slide>
      <div className="px-8">
        <TitleBlock p1={t("slide.touch.title_" + touchSetting)} />

        <div className="hidden grid-cols-1 gap-4 mt-8 text-center xs:grid">
          <Step
            title="1"
            className="mx-auto"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p1_" + touchSetting)}
          </Text>

          <Step
            title="2"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p2_" + touchSetting)}
          </Text>

          <Step
            title="3"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p3_" + touchSetting)}
          </Text>
        </div>

        <div className="grid grid-cols-[3rem,auto] mt-8 gap-4 text-left xs:hidden">
          <Step
            title="1"
            className="mx-auto"
          />
          <Text
            type="Caption A"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p1_" + touchSetting)}
          </Text>

          <Step
            title="2"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Caption A"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p2_" + touchSetting)}
          </Text>

          <Step
            title="3"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Caption A"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p3_" + touchSetting)}
          </Text>
        </div>
      </div>
    </Slide>
  );
};

export default TouchSettingsSlides;
