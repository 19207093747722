import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LicenseSide, LicenseType } from "../../enums";
import { GA } from "../../helpers/GA/ga";
import { RootState } from "../../redux";
import TitleBlock from "../common/uikit/TitleBlock";
import ThreePartLayout from "../common/layouts/ThreePartLayout";
import ButtonTab from "../common/uikit/ButtonTab";
import Text from "../common/uikit/Text";

import gray from "../../assets/upload/license_gray-front@2x.png";
import paper from "../../assets/upload/license_paper-front@2x.png";
import plastic from "../../assets/upload/license_plastic-front@2x.png";

import backGray from "../../assets/upload/license_gray-back@2x.png";
import backPaper from "../../assets/upload/license_paper-back@2x.png";
import backPlastic from "../../assets/upload/license_plastic-back@2x.png";

type Props = {
  side: LicenseSide;
  nextSlide: any;
};

function PlacementSlide(props: Props) {
  const { t } = useTranslation("upload");
  const licenseType = useSelector((state: RootState) => state.user.licenseType);

  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("");
  const [lines, setLines] = useState("");
  const [readability, setReadability] = useState("");

  useEffect(() => {
    let src = "";
    let title = "";
    let lines = "";
    let readability = "";

    switch (props.side) {
      case LicenseSide.Front1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = plastic;
            title = t("front.title.plastic");
            lines = t("front.place");
            readability = t("readability.name.plastic");
            break;
          case LicenseType.Paper:
            src = paper;
            title = t("front.title.paper");
            lines = t("front.place");
            readability = t("readability.name.paper");
            break;
          case LicenseType.Gray:
            src = gray;
            title = t("front.title.gray");
            lines = t("front.place");
            readability = t("readability.name.gray");
            break;
          default:
            break;
        }
        break;

      case LicenseSide.Back1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = backPlastic;
            title = t("back.title.plastic");
            lines = t("back.labelVisible");
            break;
          case LicenseType.Paper:
            src = backPaper;
            title = t("back.title.paper");
            lines = t("back.labelVisible");
            break;
          case LicenseType.Gray:
            src = backGray;
            title = t("back.title.gray");
            lines = t("back.labelVisible");
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    setSrc(src);
    setTitle(title);
    setLines(lines);
    setReadability(readability);
  }, [props]);

  const handleOnClick = () => {
    props.nextSlide();
    new GA().trackEvent(window, "upload_placement_click_confirm");
  };

  return (
    <ThreePartLayout
      top={
        <TitleBlock
          p1={title}
          p2={lines}
        />
      }
      middle={
        <div className="px-8">
          <div className="relative flex items-center w-full mx-auto mb-8">
            <img
              src={src}
              alt="license"
              className="object-contain"
            />
          </div>
          <div className="w-full mb-6">
            <div className="w-full p-2 mx-auto bg-white rounded">
              <Text
                type="Caption A"
                className="text-easycheck-red"
              >
                <b>{t("tip")}</b>
                <p className="inline">{readability}</p>
              </Text>
            </div>
          </div>
        </div>
      }
      bottom={
        <ButtonTab
          backButton_onClick={undefined}
          backButton_show={false}
          nextButton_onClick={handleOnClick}
          nextButton_children={t("button.understood")}
          absolute={false}
        />
      }
    />
  );
}

export default PlacementSlide;
