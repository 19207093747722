const TutorialImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="absolute inset-x-0 bottom-0 object-fill object-bottom h-auto -z-10"
    />
  );
};

export default TutorialImage;
