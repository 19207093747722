import { ConnectivityStatus } from "@prismadelabs/prismaid";
import { typedAction } from "../helpers";

export type MessageType = "info" | "error" | "success";
export type SwipeResult = { type: MessageType; text: string };

type SwipeState = {
  scaleFactor: number;
  networkStatus: ConnectivityStatus;
  progress: number;
  showProcessIndicator: boolean;
  showSwipeResults: boolean;
  showDot: boolean;
  swipeResults: SwipeResult[];
  count: number;
  transactionData: string | undefined;
  transactionDataSignature: string | undefined;
};

const initialState: SwipeState = {
  scaleFactor: 0.5,
  networkStatus: ConnectivityStatus.ok,
  progress: 0,
  showProcessIndicator: false,
  showDot: false,
  showSwipeResults: true,
  count: 0,
  swipeResults: [
    {
      type: "info",
      text: "swipe:place.body",
    },
  ],
  transactionData: undefined,
  transactionDataSignature: undefined,
};

export const resetData = () => {
  return typedAction("swipe/RESET_DATA");
};

export const setScaleFactor = (scaleFactor: number) => {
  return typedAction("swipe/SET_SCALEFACTOR", scaleFactor);
};

export const setNetworkStatus = (networkStatus: ConnectivityStatus) => {
  return typedAction("swipe/SET_NETWORK_STATUS", networkStatus);
};

export const setProgress = (progress: number) => {
  return typedAction("swipe/SET_PROGRESS", progress);
};

export const setShowProcessIndicator = (showProcessIndicator: boolean) => {
  return typedAction("swipe/SET_SHOW_PROCESS_INDICATOR", showProcessIndicator);
};
export const setShowDot = (showDot: boolean) => {
  return typedAction("swipe/SET_SHOW_DOT", showDot);
};
export const setShowSwipeResults = (showSwipeResults: boolean) => {
  return typedAction("swipe/SET_SHOW_SWIPE_RESULTS", showSwipeResults);
};

export const setCount = (count: number) => {
  return typedAction("swipe/SET_COUNT", count);
};

export const addSwipeResult = (result: SwipeResult) => {
  return typedAction("swipe/ADD_SWIPE_RESULT", result);
};

export const setSwipeResults = (results: SwipeResult[]) => {
  return typedAction("swipe/SET_SWIPE_RESULTS", results);
};

export const setTransactionData = (data: { transactionData: string | undefined; transactionDataSignature: string | undefined }) => {
  return typedAction("swipe/SET_TRANSACTION_DATA", data);
};

export const clearSwipeResults = () => {
  return typedAction("swipe/CLEAR_SWIPE_RESULTS");
};

type SwipeAction = ReturnType<
  | typeof resetData
  | typeof setScaleFactor
  | typeof setNetworkStatus
  | typeof setProgress
  | typeof setShowProcessIndicator
  | typeof setShowDot
  | typeof setShowSwipeResults
  | typeof setCount
  | typeof addSwipeResult
  | typeof setSwipeResults
  | typeof setTransactionData
  | typeof clearSwipeResults
>;

export function swipeReducer(state = initialState, action: SwipeAction): SwipeState {
  switch (action.type) {
    case "swipe/RESET_DATA":
      return {
        ...initialState,
      };
    case "swipe/SET_SCALEFACTOR":
      return {
        ...state,
        scaleFactor: action.payload,
      };
    case "swipe/SET_NETWORK_STATUS":
      return {
        ...state,
        networkStatus: action.payload,
      };
    case "swipe/SET_PROGRESS":
      return {
        ...state,
        progress: action.payload,
      };
    case "swipe/SET_SHOW_PROCESS_INDICATOR":
      return {
        ...state,
        showProcessIndicator: action.payload,
      };
    case "swipe/SET_SHOW_DOT":
      return {
        ...state,
        showDot: action.payload,
      };
    case "swipe/SET_SHOW_SWIPE_RESULTS":
      return {
        ...state,
        showSwipeResults: action.payload,
      };
    case "swipe/SET_COUNT":
      return {
        ...state,
        count: action.payload,
      };
    case "swipe/ADD_SWIPE_RESULT":
      return {
        ...state,
        swipeResults: [...state.swipeResults, action.payload],
      };
    case "swipe/SET_SWIPE_RESULTS":
      return {
        ...state,
        swipeResults: action.payload,
      };
    case "swipe/SET_TRANSACTION_DATA":
      return {
        ...state,
        transactionData: action.payload.transactionData,
        transactionDataSignature: action.payload.transactionDataSignature,
      };
    case "swipe/CLEAR_SWIPE_RESULTS":
      return {
        ...state,
        count: 0,
        swipeResults: [
          {
            type: "info",
            text: "swipe:place.body",
          },
        ],
      };
    default:
      return state;
  }
}
