import classNames from "classnames";
import { SwipeResult } from "../../../redux/modules/swipe";
import Text from "./Text";

const MessageBlock = ({ type, text }: SwipeResult) => {
  return (
    <div
      className={classNames(
        "absolute top-0 left-0 touch-none select-none",
        "text-white px-8 py-8",
        "w-[50%] rounded-br-xl",
        "fullBlock:w-full fullBlock:rounded-none",
        type === "error" && "bg-edding-red",
        type === "success" && "bg-easycheck-success-green",
        type === "info" && "bg-easycheck-bluepurple"
      )}
    >
      <Text
        type="Body"
        className="mt-2 text-white"
      >
        {text}
      </Text>
    </div>
  );
};

export default MessageBlock;
