import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TitleBlock from "../components/common/uikit/TitleBlock";
import BackButton from "../components/common/uikit/BackButton";
import Button from "../components/common/uikit/Button";
import Text from "../components/common/uikit/Text";
import { LicenseType } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";
import { setLicenseType } from "../redux/modules/user";

import gray from "../assets/select/license_gray@2x.png";
import paper from "../assets/select/license_paper@2x.png";
import plastic from "../assets/select/license_plastic@2x.png";
import { GA } from "../helpers/GA/ga";

export const Select = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("app");

  useInvocationCheck();

  const [emblaRef, emblaApi] = useEmblaCarousel();

  const { type } = useSelector((state: RootState) => {
    return {
      type: state.user.licenseType,
    };
  });

  // TODO remove, and use active/inactiveSlide instead?
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    switch (type) {
      case 0:
        setSelectedType("plastic");
        emblaApi?.scrollTo(0);
        break;
      case 1:
        setSelectedType("paper");
        emblaApi?.scrollTo(1);
        break;
      case 2:
        setSelectedType("gray");
        emblaApi?.scrollTo(2);
        break;
      default:
        setSelectedType("plastic");
        emblaApi?.scrollTo(0);
        break;
    }
  }, [emblaApi, type]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("select", () => {
      switch (emblaApi.selectedScrollSnap()) {
        case 0:
          dispatch(setLicenseType(LicenseType.Plastic));
          break;
        case 1:
          dispatch(setLicenseType(LicenseType.Paper));
          break;
        case 2:
          dispatch(setLicenseType(LicenseType.Gray));
          break;
        default:
      }
      new GA().trackEvent(window, "select_swipe");
    });
  }, [emblaApi]);

  const onPrevButtonClick = useCallback(() => {
    history.push("/welcome");
  }, []);

  const onNextButtonClick = useCallback(() => {
    history.push("/onboarding");
  }, [history]);

  return (
    <div className="flex flex-col justify-between py-4 text-center min-h-dvh">
      <div className="px-8 topSpacing">
        {/* <div className="mt-8"> */}
        <TitleBlock p1={t("select.p1")} />
      </div>

      <div
        className="flex flex-col mt-4 overflow-hidden grow"
        ref={emblaRef}
      >
        <div className="flex grow">
          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 ml-[2rem] flex">
            <div className="h-50dvh p-4 my-auto">
              <div
                className={classNames(
                  "p-4 rounded-lg transition-colors ease-in-out duration-200",
                  selectedType === "plastic" && "bg-easycheck-bluepurple",
                  selectedType !== "plastic" && "bg-white"
                )}
              >
                <img
                  src={plastic}
                  alt={t("select.plastic")}
                />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("select.plastic")}</Text>
                <Text type="Caption A">{t("select.plastic.issued")}</Text>
              </div>
            </div>
          </div>

          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem] flex">
            <div className="h-50dvh p-4 my-auto">
              <div className={classNames("p-4 rounded-lg", selectedType === "paper" && "bg-easycheck-bluepurple", selectedType !== "paper" && "bg-white")}>
                <img
                  src={paper}
                  alt={t("select.paper")}
                />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("select.paper")}</Text>
                <Text type="Caption A">{t("select.paper.issued")}</Text>
              </div>
            </div>
          </div>

          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem] mr-[2rem] flex">
            <div className="h-50dvh p-4 my-auto">
              <div className={classNames("p-4 rounded-lg", selectedType === "gray" && "bg-easycheck-bluepurple", selectedType !== "gray" && "bg-white")}>
                <img
                  src={gray}
                  alt={t("select.gray")}
                />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("select.gray")}</Text>
                <Text type="Caption A">{t("select.gray.issued")}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="items-center justify-between hidden px-8 xs:flex">
        <BackButton onClick={onPrevButtonClick} />
        <Button
          onClick={onNextButtonClick}
          disabled={!selectedType}
        >
          {t("button.next")}
        </Button>
      </div>
      <div className="flex items-center justify-between px-8 xs:hidden">
        <BackButton
          onClick={onPrevButtonClick}
          size="small"
        />
        <Button
          onClick={onNextButtonClick}
          disabled={!selectedType}
          size="small"
        >
          {t("button.next")}
        </Button>
      </div>
    </div>
  );
};

export default Select;
