import classNames from "classnames";
import React, { ReactNode } from "react";

// types
type CenteredLayoutProps = {
  children: ReactNode;
  className?: string;
};

// component
class CenteredLayout extends React.Component<CenteredLayoutProps> {
  render() {
    return (
      <div
        className={classNames(
          "flex items-center h-dvh",
          this.props.className
        )}
      >
        <div className="w-full mx-8">{this.props.children}</div>
      </div>
    );
  }
}

export default CenteredLayout;
