import MessageBlock from "../common/uikit/MessageBlock";
import { RootState } from "../../redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const SwipeMessage = () => {
  const { t } = useTranslation("swipe");

  const { results, showSwipeResults } = useSelector((state: RootState) => {
    return {
      results: state.swipe.swipeResults,
      showSwipeResults: state.swipe.showSwipeResults,
    };
  });

  return (
    <div className="relative w-screen">
      <TransitionGroup>
        {results.map(
          (result, index) =>
            index === results.length - 1 &&
            showSwipeResults && (
              <CSSTransition
                key={index}
                classNames="showHide"
                timeout={100}
              >
                <MessageBlock
                  type={result.type}
                  text={t(result.text)}
                />
              </CSSTransition>
            )
        )}
      </TransitionGroup>
    </div>
  );
};

export default SwipeMessage;
