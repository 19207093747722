import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux";
import { useHistory } from "react-router-dom";
import { setErrorCode } from "../redux/modules/error";
import { ErrorCode } from "../enums";

function useInvocationCheck() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { invocationIsValid } = useSelector((state: RootState) => {
    return { invocationIsValid: state.app.invocationIsValid };
  });

  useEffect(() => {
    if (!invocationIsValid) {
      dispatch(setErrorCode(ErrorCode.InvalidInvocation));
      history.push("/error");
      return;
    }
  }, []);
}

export default useInvocationCheck;
