import { PrismaSDK } from "@prismadelabs/prismaid";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SDKSingleton from "../SDK";
import { ErrorCode, LicenseType } from "../enums";
import { GA } from "../helpers/GA/ga";
import { setInvocationIsValid, setIsRollout, setSlideCount } from "../redux/modules/app";
import { setErrorCode } from "../redux/modules/error";
import { setDriverId, setGreeting, setLicenseIsKnown, setLicenseType, setQuizNumber, setSkipUpload, setUploadUrl } from "../redux/modules/user";

function Upload() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isMobileDevice = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)) {
      return true;
    } else {
      return false;
    }
  };

  const setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    dispatch(setErrorCode(errorCode));
    history.replace("/error");
  };

  useEffect(() => {
    // invoke sdk to set the language
    const sdk: PrismaSDK = SDKSingleton.getInstance().sdk;

    // 192.168.1.35:4096/upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=https%3A%2F%2Fedding.de&licenseType=0
    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=0
    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=1
    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=2
    const parsed = queryString.parse(window.location.search);
    var data = {} as any;

    if (parsed.greeting) {
      dispatch(setGreeting(parsed.greeting.toString()));

      data = { ...data, greeting: parsed.greeting };
    }

    let needsSlideForLicenseSelection = true;
    if (parsed.driverId) {
      dispatch(setDriverId(parsed.driverId.toString()));
      data = { ...data, driverId: parsed.driverId };
    }
    if (parsed.licenseType === LicenseType.Plastic.toString()) {
      dispatch(setLicenseType(LicenseType.Plastic));
      dispatch(setLicenseIsKnown(true));
      data = { ...data, licenseType: LicenseType.Plastic };
      needsSlideForLicenseSelection = false;
    }
    if (parsed.licenseType === LicenseType.Paper.toString()) {
      dispatch(setLicenseType(LicenseType.Paper));
      dispatch(setLicenseIsKnown(true));
      data = { ...data, licenseType: LicenseType.Paper };
      needsSlideForLicenseSelection = false;
    }
    if (parsed.licenseType === LicenseType.Gray.toString()) {
      dispatch(setLicenseType(LicenseType.Gray));
      dispatch(setLicenseIsKnown(true));
      data = { ...data, licenseType: LicenseType.Gray };
      needsSlideForLicenseSelection = false;
    }
    if (parsed.skipUpload === "true") {
      dispatch(setSkipUpload(true));
      data = { ...data, skipUpload: true };
    }

    let count = 6;

    if (needsSlideForLicenseSelection) {
      count = count + 1;
    }

    dispatch(setSlideCount(count));
    data = { ...data, count: count };

    if (parsed.rollout === "true") {
      dispatch(setIsRollout(true));
      data = { ...data, rollout: true };
      count = 6;
    } else {
      dispatch(setIsRollout(false));
      data = { ...data, rollout: false };
    }

    if (parsed.quizNumber) {
      let quiz = "task." + parsed.quizNumber;
      // currently same task for both front pictures
      dispatch(setQuizNumber(quiz));
      data = { ...data, quizNumber: quiz };
    }

    // url is the endpoint where to send form data, not to be confused with the open-param with the same name
    if (parsed.uploadUrl) {
      dispatch(setUploadUrl(parsed.uploadUrl.toString()));
      data = { ...data, uploadUrl: parsed.uploadUrl.toString() };
    }

    if (parsed.pending === "true") {
      data = { ...data, pending: true };
    }

    console.log(data);

    if (!isMobileDevice()) {
      new GA().trackEvent(window, "upload_unsupportedDevice");
      setErrorCodeAndRedirect(ErrorCode.InvalidUploadDevice);
    } else if (data.hasOwnProperty("pending") && data.pending) {
      new GA().trackEvent(window, "upload_isPending");
      setErrorCodeAndRedirect(ErrorCode.UploadIsPending);
    } else if (data.hasOwnProperty("greeting") && data.hasOwnProperty("driverId") && data.hasOwnProperty("quizNumber") && data.hasOwnProperty("uploadUrl")) {
      dispatch(setInvocationIsValid(true));
      setTimeout(() => {
        history.replace("/upload/start");
      }, 1000);
    } else {
      console.log(data);
      new GA().trackEvent(window, "upload_missingParameters");
      setErrorCodeAndRedirect(ErrorCode.InvalidUploadLink);
    }
  }, []);

  return <div className="flex items-center justify-center h-dvh"></div>;
}

export default Upload;
