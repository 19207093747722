import { now } from "moment";

export const getImageData = (e: any) => {
  return {
    file: e.target.files[0],
    lastModified: e.target.files[0].lastModified,
    name: e.target.files[0].name,
    size: e.target.files[0].size,
    type: e.target.files[0].type,
    filepath: e.target.value,
    photoURL: URL.createObjectURL(e.target.files[0]),
    selectedOn: now(),
  };
};
