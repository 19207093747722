import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Text from "../common/uikit/Text";

import OptionCalendly from "./options/OptionCalendly";
import OptionManual from "./options/OptionManual";
import OptionPhotoUpload from "./options/OptionPhotoUpload";
import OptionQRCode from "./options/OptionQRCode";
import { GA } from "../../helpers/GA/ga";

interface Props {
  // TODO clean up unused options
  incompatibleDevice?: boolean;
  sorry?: boolean;
  excuse2?: boolean;
  excuse3?: boolean;
  suggestion1?: boolean;
  suggestion2?: boolean;
  qrCode?: boolean;
  uploadUrl?: string;
  manualControl?: boolean;
  calendly?: boolean;
}

const UniversalFailureScreen: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.qrCode) {
      new GA().trackEvent(window, "failure_show_qr");
    }
    if (props.uploadUrl) {
      new GA().trackEvent(window, "failure_show_photo");
    }
    if (props.calendly) {
      new GA().trackEvent(window, "failure_show_support");
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0"
        />
      </Helmet>
      <div className="flex flex-col justify-between flex-grow h-full gap-4 px-8 py-4 whitespace-pre-wrap bg-white min-h-dvh">
        <div className="mx-4 mt-4 mb-2 space-y-2 font-bold text-center">
          <p>{props.incompatibleDevice && t("failure:incompatibleDevice")}</p>
          <p>{props.sorry && t("failure:sorry")}</p>
          <p>{props.excuse2 && t("failure:excuse2")}</p>
          <p>{props.excuse3 && t("failure:excuse3")}</p>
          {props.suggestion1 && <Text type={"Headline M"}>{t("failure:suggestion1")}</Text>}
        </div>

        <div className="space-y-4">
          {props.qrCode && <OptionQRCode />}
          {props.uploadUrl && <OptionPhotoUpload uploadUrl={props.uploadUrl} />}
          {props.manualControl && <OptionManual />}
          {props.calendly && <OptionCalendly />}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default UniversalFailureScreen;
