import React from "react";

const isPortrait = () => {
  return window.innerHeight >= window.innerWidth;
};

type OrientationChangeProviderProps = {
  children: React.ReactNode;
};

type OrientationChangeProviderState = {
  orientation: string;
};

export class OrientationChangeProvider extends React.Component<
  OrientationChangeProviderProps,
  OrientationChangeProviderState
> {
  constructor(props: OrientationChangeProviderProps) {
    super(props);

    this.state = {
      orientation: isPortrait() ? "portrait" : "landscape",
    };

    window.addEventListener("resize", () => {
      this.setState({
        orientation: isPortrait() ? "portrait" : "landscape",
      });
    });
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return (
      this.state.orientation !== nextState.orientation ||
      this.props.children !== nextProps.children
    );
  }

  render() {
    return <div key={Date.now()}>{this.props.children}</div>;
  }
}
