import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";
import Text from "./Text";

type StepProps = {
  title: string;
  className?: string;
  asChild?: boolean;
};

const baseClasses = "bg-white rounded-full inline-flex items-center justify-center size-[2.375rem]";

const Step = ({ title, asChild, className, ...restProps }: StepProps) => {
  const Comp = asChild ? Slot : "div";

  return (
    <Comp
      {...restProps}
      className={classNames(baseClasses, className)}
    >
      <Text type="Headline M">{title}</Text>
    </Comp>
  );
};

export default Step;
