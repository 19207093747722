import classNames from "classnames";
import { ReactNode } from "react";

const ThreePartLayout = ({ top, middle, bottom, topSpacing = true }: { top?: ReactNode; middle?: ReactNode; bottom?: ReactNode; topSpacing?: boolean }) => {
  return (
    <div className="flex flex-col justify-between py-4 overflow-scroll text-center whitespace-pre-wrap h-dvh">
      <div className={classNames("px-8", top && topSpacing && "topSpacing")}>{top}</div>
      <div className="">{middle}</div>
      <div className="">{bottom}</div>
    </div>
  );
};

export default ThreePartLayout;
