import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as Check } from "../assets/icons/icon-check.svg";
import BackButton from "../components/common/uikit/BackButton";
import Button from "../components/common/uikit/Button";
import Text from "../components/common/uikit/Text";
import { registerMovementDetection } from "../helpers/MovementDetection";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { useDispatch } from "react-redux";
import { clearSwipeResults } from "../redux/modules/swipe";

export const Ready = () => {
  const history = useHistory();
  const { t } = useTranslation("app");
  const dispatch = useDispatch();

  useInvocationCheck();

  const onPrevButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const onNextButtonClick = useCallback(() => {
    registerMovementDetection();
    dispatch(clearSwipeResults());
    history.push("/swipe");
  }, [history]);

  return (
    <div className="flex flex-col items-center justify-center px-8 py-4 text-center min-h-dvh">
      <div>
        <Check className="w-6 h-6 mx-auto text-easycheck-bluepurple" />
        <Text
          type="Headline M"
          className="mt-4"
        >
          {t("ready.p1")}
        </Text>
        <Text
          type="Headline XL"
          className="mt-12"
        >
          {t("ready.p2")}
        </Text>

        <div className="mt-12">
          <Button onClick={onNextButtonClick}>{t("button.ready")}</Button>
        </div>
      </div>

      <>
        <div className="absolute inset-x-0 bottom-[1rem] items-center justify-between hidden px-8 xs:flex">
          <BackButton onClick={onPrevButtonClick} />
        </div>
        <div className="absolute inset-x-0 bottom-[1rem] flex items-center justify-between px-8 xs:hidden">
          <BackButton
            onClick={onPrevButtonClick}
            size="small"
          />
        </div>
      </>
    </div>
  );
};

export default Ready;
