import { ReactNode } from "react";
import BackButton from "../uikit/BackButton";
import Button from "../uikit/Button";
import classNames from "classnames";

type ButtonTabProps = {
  backButton_onClick: any;
  backButton_show?: boolean;

  nextButton_onClick: any;
  nextButton_children: ReactNode;
  nextButton_show?: boolean;

  absolute?: boolean;
};

const ButtonTab = ({
  backButton_onClick,
  backButton_show = true,
  nextButton_onClick,
  nextButton_children,
  nextButton_show = true,
  absolute = true,
}: ButtonTabProps) => {
  return (
    <>
      <div className={classNames(absolute && "absolute", "inset-x-0 bottom-4 items-center justify-between hidden px-8 xs:flex")}>
        {backButton_show ? <BackButton onClick={backButton_onClick} /> : <div></div>}
        {nextButton_show ? <Button onClick={nextButton_onClick}>{nextButton_children}</Button> : <div></div>}
      </div>
      <div className={classNames(absolute && "absolute", "inset-x-0 bottom-[1rem] flex items-center justify-between px-8 xs:hidden")}>
        {backButton_show ? (
          <BackButton
            onClick={backButton_onClick}
            size="small"
          />
        ) : (
          <div></div>
        )}
        {nextButton_show ? (
          <Button
            onClick={nextButton_onClick}
            size="small"
          >
            {nextButton_children}
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default ButtonTab;
