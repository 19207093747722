import { useRef } from "react";

const TutorialVideo = ({ src }: { src: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <video
      src={src}
      autoPlay
      muted
      playsInline
      className="absolute inset-x-0 bottom-0 mx-auto -z-10"
      ref={videoRef}
      onEnded={() => {
        setTimeout(() => {
          if (videoRef.current) {
            videoRef.current.play();
          }
        }, 2000);
      }}
    />
  );
};

export default TutorialVideo;
