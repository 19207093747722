import { combineReducers } from "redux";
import { userReducer } from "./modules/user";
import { appReducer } from "./modules/app";
import { selectReducer } from "./modules/select";
import { swipeReducer } from "./modules/swipe";
import { errorReducer } from "./modules/error";
import { cookieReducer } from "./modules/cookie";
import { onboardingReducer } from "./modules/onboarding";

export const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  select: selectReducer,
  swipe: swipeReducer,
  error: errorReducer,
  cookie: cookieReducer,
  onboarding: onboardingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
