import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as Calendly } from "../../../assets/icons/icon-calendly.svg";
import { RootState } from "../../../redux";
import Text from "../../common/uikit/Text";
import { GA } from "../../../helpers/GA/ga";

const OptionCalendly = () => {
  const { t } = useTranslation();

  const eventUrl = useSelector((state: RootState) => state.user.eventUrl);
  const [calendlyUrl, setCalendlyUrl] = useState("");

  useEffect(() => {
    if (!eventUrl) {
      setCalendlyUrl("https://calendly.com/easycheck-by-edding/fsk");
    } else {
      setCalendlyUrl(eventUrl!);
    }
  }, [eventUrl]);

  return (
    <div className="rounded bg-edding-blue bg-opacity-15">
      <div className="px-8 py-6 mx-auto md:max-w-sm">
        <a
          href={calendlyUrl}
          onClick={() => {
            new GA().trackEvent(window, "failure_click_support");
          }}
          className="flex flex-row items-center space-x-6 text-left"
        >
          <Calendly className="flex-grow-0 w-9 h-9 text-easycheck-bluepurple" />
          <div>
            <Text type="Headline M">{t("failure:calendly1")}</Text>
            <Text
              type="Caption A"
              className="hidden xs:block"
            >
              {t("failure:calendly2")}
            </Text>
          </div>
        </a>
      </div>
    </div>
  );
};

export default OptionCalendly;
