import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ConfirmSlide from "../components/upload/ConfirmSlide";
import { LicenseSide } from "../enums";
import useInvocationCheck from "../hooks/useInvocationCheck";
import { RootState } from "../redux";
import { setUploadData } from "../redux/modules/app";

function UploadBackConfirm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const quizNumber = useSelector((state: RootState) => state.user.quizNumber);
  const data = useSelector((state: RootState) => state.app.uploadData);

  useInvocationCheck();

  const handleOnClick = () => {
    history.push("/upload/back/place");
  };

  const takePhoto = (index: number, image: any, retry: boolean = false) => {
    let images = [...data];
    images[index] = image;
    dispatch(setUploadData(images));

    if (!retry) {
      history.push("/upload/back/confirm");
    }
  };

  return (
    <ConfirmSlide
      side={LicenseSide.Back1}
      task={quizNumber}
      takePhoto={(image: any) => {
        takePhoto(2, image, true);
      }}
      image={data[2]}
      nextSlide={handleOnClick}
      isFinalUpload={true}
    />
  );
}

export default UploadBackConfirm;
