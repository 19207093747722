import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Code from "../common/Code";
import Button from "../common/uikit/Button";
import ButtonTab from "../common/uikit/ButtonTab";
import Text from "../common/uikit/Text";
import { RootState } from "../../redux";
import { GA, GAEventType } from "../../helpers/GA/ga";

export const Solution = ({ type }: { type: "phone" | "zoom" | "phoneAndZoom" }) => {
  const history = useHistory();
  const { t } = useTranslation("app");

  const { qrcode } = useSelector((state: RootState) => {
    return {
      qrcode: state.user.originalURL,
    };
  });

  const onPrevButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const onNextButtonClick = useCallback(() => {
    new GA().trackEvent(window, ("troubleshoot_click_override_" + type) as GAEventType);
    history.push("/ready");
  }, [history]);

  const onNotPossibleClick = useCallback(() => {
    history.push("/failure");
  }, [history]);

  return (
    <div className="flex flex-col text-center min-h-dvh">
      <div className="px-8 topSpacing">
        <Text type="Headline M">{t("troubleshoot.title." + type)}</Text>
      </div>

      <div className="flex flex-col justify-center px-8 mb-20 grow">
        <div>
          <div className="w-40 h-40 mx-auto my-8">
            <Code
              url={qrcode}
              // TODO read easycheck-light-blue from config
              bgColor="#F1F6FF"
            />
          </div>
          <div className="mt-8">
            <span className="hidden w-full xs:block">
              <Text type="Headline M">{t("troubleshoot.qrcode")}</Text>
            </span>
            <span className="xs:hidden">
              <Text type="Body">{t("troubleshoot.qrcode")}</Text>
            </span>
          </div>

          <div className="mt-4">
            <Button
              onClick={onNotPossibleClick}
              weight="secondary"
              size="medium"
            >
              {t("button.notPossible")}
            </Button>
          </div>
        </div>
      </div>

      <ButtonTab
        backButton_onClick={onPrevButtonClick}
        nextButton_onClick={onNextButtonClick}
        nextButton_children={t("button.anyways_next")}
      />
    </div>
  );
};

export default Solution;
