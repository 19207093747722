import { useTranslation } from "react-i18next";
import { ReactComponent as Manual } from "../../../assets/icons/icon-manual.svg";
import Text from "../../common/uikit/Text";

const OptionManual = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="rounded bg-edding-blue bg-opacity-15">
        <div className="px-8 py-6 mx-auto md:max-w-sm">
          <div className="flex flex-row items-center space-x-6 text-left">
            <Manual className="flex-grow-0 w-9 h-9 text-easycheck-bluepurple" />
            <div>
              <Text type="Headline M">{t("failure:manual1")}</Text>
              <Text
                type="Caption A"
                className="hidden xs:block"
              >
                {t("failure:manual2")}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionManual;
