import React, { createRef } from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// types
const mapStateToProps = (state: RootState) => ({
  progress: state.swipe.progress,
});

const mapDispatchToProps = {};

type ProgressBarProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type ProgressBarStates = {};

// component
class ProgressBar extends React.Component<ProgressBarProps, ProgressBarStates> {
  private containerRef = createRef<HTMLDivElement>();

  constructor(props: ProgressBarProps) {
    super(props);

    const container = this.containerRef.current;
    if (container) {
      disableBodyScroll(container);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    return (
      <div
        ref={this.containerRef}
        className="absolute bottom-0 flex w-screen overflow-hidden opacity-75 bg-status-green"
        style={{
          height: `${this.props.progress > 5 ? this.props.progress : 0}%`,
          touchAction: "none",
        }}
      ></div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
